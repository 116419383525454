#header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 5px 9px -7px rgba(186, 180, 186, 1);
  width: 100%;
  min-width: 100%;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  color: #000;
}

.lineChart {
  width: 250px;
  height: 200px;
  margin-bottom: 0px;
}

#header > #navItemContainer {
  flex: 85%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 100%;
}

#profileImage {
  flex: 15%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 24px;
  height: 100%;
}

#profileImage > div {
  cursor: pointer;
  box-sizing: border-box;
  border-top: 3px solid #fff;
}

#profileImage p {
  margin: 10px;
  margin-left: 5px;
  font-size: 15px;
}

.practitionerSection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 20px;
  position: relative;
}

.halfOpacity {
  opacity: 0.5;
}

.profileIcon {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: #3f99f7;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
}

.profileIcon:hover,
.profileIcon:focus {
  background-color: #0a5eb5;
}

.profileIcon img {
  width: 31px;
  height: 31px;
}

.fabContainer {
  bottom: 0;
  position: fixed;
  margin: 1em;
  right: 0px;
  cursor: pointer;
  z-index: 99999;
}

.fabButton {
  box-shadow:
    0px 5px 11px -2px rgba(0, 0, 0, 0.18),
    0px 4px 12px -7px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: block;
  width: 56px;
  height: 56px;
  margin: 20px auto 0;
  position: relative;
  background-color: #fff;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  cursor: pointer;
  color: rgba(0, 0, 0, 0);
}

@media not all and (hover: none) {
  .fabButton:active,
  .fabButton:focus,
  .fabButton:hover {
    box-shadow:
      0 0 4px rgba(0, 0, 0, 0.14),
      0 4px 8px rgba(0, 0, 0, 0.28);
    cursor: pointer;
  }
}

.fabButton:not(:last-child) {
  width: 0px;
  height: 0px;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0;
  opacity: 0;
  visibility: visible;
  pointer-events: none;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}

.showFabButton {
  width: 40px !important;
  height: 40px !important;
  margin: 20px auto 0 !important;
  opacity: 1 !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  margin: 15px auto 0 !important;
  visibility: visible !important;
  pointer-events: auto !important;
}

/* .fabContainer:hover
.fabButton:not(:last-child) {
  width: 40px;
  height: 40px;
  margin: 20px auto 0;
  opacity: 1;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  margin: 15px auto 0;
  visibility: visible;
  pointer-events: auto;
} */

.fabButton[tooltip]:before {
  bottom: 25%;
  font-family: arial;
  font-weight: 600;
  border-radius: 2px;
  background: #585858;
  color: #fff;
  content: attr(tooltip);
  font-size: 12px;
  visibility: hidden;
  opacity: 0;
  padding: 5px 7px;
  margin-right: 12px;
  position: absolute;
  right: 100%;
  white-space: nowrap;
  text-align: center;
}

.fabButton[tooltip]:before,
.fabButton[tooltip]:after {
  visibility: visible;
  opacity: 1;
}

.rotateFabButton {
  transform: rotate(45deg);
}

.fabButton:nth-last-child(1) {
  -webkit-transition-delay: 25ms;
  transition-delay: 25ms;
  background-image: url("menu.png");
  background-size: contain;
}

/* Unessential styling for sliding up buttons at differnt speeds */
.fabButton:nth-last-child(2) {
  -webkit-transition-delay: 25ms;
  transition-delay: 25ms;
  background-image: url("home.svg");
  background-size: contain;
}

.fabButton:not(:last-child):nth-last-child(3) {
  -webkit-transition-delay: 50ms;
  transition-delay: 20ms;
  background-image: url("hat.svg");
  background-size: contain;
}

.fabButton:not(:last-child):nth-last-child(4) {
  -webkit-transition-delay: 75ms;
  transition-delay: 40ms;
  background-image: url("pencil.svg");
  background-color: "#3F99F7";
  background-size: contain;
}

.fabButton:not(:last-child):nth-last-child(5) {
  -webkit-transition-delay: 100ms;
  transition-delay: 60ms;
  background-image: url("description.svg");
  background-size: contain;
}

.fabButton:not(:last-child):nth-last-child(6) {
  -webkit-transition-delay: 125;
  transition-delay: 75ms;
  background-image: url("ladder.svg");
  background-size: contain;
}

.fabButton:not(:last-child):nth-last-child(7) {
  -webkit-transition-delay: 150ms;
  transition-delay: 90ms;
  background-image: url("atom.svg");
  background-size: contain;
}

.fabButton:not(:last-child):nth-last-child(8) {
  -webkit-transition-delay: 175ms;
  transition-delay: 104ms;
  background-image: url("spa.svg");
  background-size: contain;
}

#subHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid #cbcbcb;
  text-align: center;
}

#subHeader > img {
  height: auto;
  width: 203px;
}

.navItemContainer {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navItemHome {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.navItemHome a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navItemNotification {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #ff6a8e;
  height: 18px;
  width: auto;
  min-width: 18px;
  padding: 2px;
  right: 5px;
  top: 3px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 3px;
}

.navItemNotification > span {
  display: block;
  color: #fff;
}

.custom-select {
  background-color: #3f99f7;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  font-weight: 400;
  outline: 0px;
}

.custom-select:active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.custom-select select {
  margin-right: 20px;
}

.otherToolsNotification-extras {
  right: 0px;
  left: 0px;
  top: -5px;
  margin-left: 10px;
  color: #fff;
}

.unlockNotification {
  right: -13px;
  width: auto;
  font-weight: 600;
  min-width: 35px;
  padding: 2px 7px;
  letter-spacing: 0.05em;
}

.activeNavItem {
  border-top: 3px solid #3f99f7 !important;
  color: #3b9af7 !important;
  font-weight: 500;
}

.activeNavItemNoBackground {
  border-top: 3px solid #3f99f7 !important;
  color: #3b9af7 !important;
  font-weight: 500;
}

.activeNavItemNoBackground:hover,
.activeNavItemNoBackground:focus {
  border-top: 3px solid #0a5eb5 !important;
  color: #0a5eb5 !important;
}

.navItem {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
  font-size: 15px;
  text-decoration: none;
  color: inherit;
  border-top: 3px solid transparent;
}

.sidebarItemSession {
  padding: 10px;
  text-decoration: none;
  color: #000;
}

.BENavDropdownContainer {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  z-index: 4;
  top: 66px;
  left: 0px;
  height: 200px;
  width: 400px;
  background-color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.BENavDropdownItem {
  display: flex;
  flex-flow: column nowrap;
  border-top: 1px solid lightgrey;
  height: 50%;
  padding: 20px;
  box-sizing: border-box;
}

.BENavDropdownItem header {
  font-weight: 500;
  font-size: 14px;
}

.BENavDropdownItemBody {
  display: flex;
  flex: 1;
  justify-self: flex-end;
  flex-flow: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 300;
  margin-top: 10px;
}

.BENavDropdownItemBody > :first-child {
  display: block;
  width: 60%;
  white-space: normal;
  color: rgba(0, 0, 0, 0.6);
}

.BENavDropdownItemBody > :not(:first-child) {
  display: block;
  color: #3f99f7;
  font-weight: 500;
}

.label {
  display: block;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.84);
}

.textField {
  height: 100%;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #f7f7f7;
  padding: 10px;
  color: #000000;
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 400;
  resize: none;
}

.textField-barrier {
  height: 75px;
}

.textField-expand {
  border: 1px solid #cccccc;
  background-color: #f6f6f6;
  height: 28px;
  width: 100%;
  color: #000000;
  font-size: 16px;
  padding: 12px;
  margin-bottom: 20px;
}

.textField-fill {
  border: 0px;
  border-bottom: 1px solid #f6f6f6;
  background-color: #f6f6f6;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  color: #000000;
  font-size: 16px;
  padding: 5px;
}

.disabled {
  cursor: not-allowed;
  background-color: grey;
  border-color: grey;
}

.disabledOverlay {
  z-index: 3;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 66px;
  background-color: rgba(0, 0, 0, 0.3);
}

.link {
  font-size: 14px !important;
  font-style: italic;
  color: #3f99f7;
  cursor: pointer;
  font-weight: 400 !important;
}

.darkBlue {
  background-color: #3a4f5f;
  color: #fff;
  border: 1px solid #3a4f5f;
  border-radius: 4px;
}

.textArea {
  font-weight: 400;
  display: block;
  border: 1px solid #cccccc;
  background-color: #f6f6f6;
  width: 100%;
  color: #000000;
  padding: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
  display: flex;
  overflow-y: hidden;
}

.roundBorder {
  border-radius: 5px;
}

.disabled {
  cursor: not-allowed;
  background-color: grey;
  border-color: grey;
  color: #fff;
}

.toolStepCount {
  color: #c9ced3;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

.toolStepDotsContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.toolStepDots {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.toolStepDots > div {
  width: 8px;
  height: 8px;
  background-color: #e9ecef;
  border-radius: 50%;
  margin: 5px;
}

.toolDiscardBtn {
  cursor: pointer;
  text-align: center;
  color: #3f99f7;
}

.activeToolStepDot {
  background-color: #3f99f7 !important;
}

.profilePadding {
  padding: 20px;
  box-sizing: border-box;
}

.fs-Modal {
  position: relative;
  width: auto;
  max-width: 950px;
  height: 80%;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  overflow: auto;
  border-radius: 10px;
  border: 2px solid #3f99f7;
  max-width: 800px;
}

.practitionerBio {
  position: relative;
  width: auto;
  max-width: 950px;
  height: 60%;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  overflow: auto;
  border-radius: 10px;
  border: 2px solid #3f99f7;
}

.buildProgramError {
  height: 132px;
}

.updateCard {
  position: relative;
  width: auto;
  max-width: 950px;
  height: 350px;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  overflow: auto;
  border-radius: 10px;
  border: 2px solid #3f99f7;
}

.fs-Modal > .fs-ModalContent {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: auto;
  padding: 50px !important;
  padding-top: 0px;
  width: 100%;
  box-sizing: border-box;
}

.practitionerBio > .fs-ModalContent {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: auto;
  padding: 20px;
  padding-top: 10px;
  width: 100%;
  box-sizing: border-box;
}

.twoWeekReview .fs-ModalContent {
  padding-top: 0px;
}

.twoWeekReviewTitle {
  text-align: center;
  font-weight: 600;
}

.twoWeekReviewBody {
  text-align: center;
  width: 400px;
}

.sessionBuilderOptions {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.sessionBuilderOptions > label {
  margin-right: 10px;
}

.sessionBuilderOptions > select {
  padding: 5px;
  border-radius: 5px;
}

.sessionBuilderOptions > select::-ms-expand {
  padding: 5px;
  border-radius: 5px;
}

@media only screen and (max-width: 1000px) {
  #twoWeekModal .modalContent {
    width: 100% !important;
  }
}

.textFloat {
  background-color: #f6f6f6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 0;
  border-bottom: 3px solid #3f99f7;
  color: #000;
  font-size: 16px;
  padding: 12px;
  margin: 1px;
}

.textBox {
  border: 1px solid #f1f1f1;
  background-color: #fff;
  height: 30px !important;
  padding: 5px;
  font-size: 13px !important;
}

.inputContainer > label {
  margin-bottom: 20px;
  display: block;
  font-size: 14px;
  color: #000;
}

.errorMessage {
  display: block;
  color: red;
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.errorBox {
  display: flex;
  align-items: center;
  background-color: #ff7070;
  border: 2px solid red;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  width: 50%;
  height: 50px;
  vertical-align: middle;
}

.successBox {
  background-color: #90ee90;
  border: 2px solid green;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  width: 50%;
  height: 50px;
  vertical-align: middle;
}

.clientWrapper {
  display: flex;
  flex: 1;
}

.sidebarContentClient {
  position: absolute;
  min-width: 347px;
  max-width: 347px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-color: #f6f6f6;
  border-right: 1px solid #f2f2f2;
}

#sideBarItem {
  height: auto;
  cursor: pointer;
  border-bottom: 1px solid #cccccc;
  padding: 10px;
}

.itemFocus {
  border-left: 10px solid #3f99f7;
  color: #000;
  background-color: #fff;
}

#sideBarItem > #sidebarItemContent {
  width: 100%;
  max-width: calc(100% - 40px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
  margin-left: 19px;
}

#sideBarItem > #sidebarItemContent > div {
  display: flex;
  margin-top: 10px;
}

.sidebarContentClient {
  position: absolute;
  min-width: 347px;
  max-width: 347px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  background-color: #f6f6f6;
  border-right: 1px solid #f2f2f2;
}

.sideBarItemSession {
  height: 100px;
  max-height: 100px;
  min-height: 100px;
  width: 347px;
  min-width: 347px;
  max-width: 347px;
  cursor: pointer;
  border-bottom: 1px solid #cccccc;
}

.sideBarItemSession > .sidebarItemContentSession {
  position: absolute;
  height: 80px;
  width: 257px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  margin-left: 60px;
  overflow: hidden;
}

.sideBarItemSession > .sidebarItemContentSession > .sidebarItemMetadataSession {
  height: 18px;
  font-weight: 500;
}

.sideBarItemSession > .sidebarItemContentSession > .sidebarItemDescriptionSession {
  opacity: 0.4;
  max-height: 54px;
  overflow: hidden;
}

#mainIcon {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 40px;
}

#mainIcon img {
  height: auto;
  min-height: 195px;
  width: 600px;
}

.indexTitle {
  font-size: 26px;
}

.indexDescription {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
  padding: 10px;
  overflow: visible;
}

#formContainer {
  width: 650px;
  margin: 0 auto;
  margin-top: 40px;
}

#formContainer > p {
  font-size: 20px;
  font-weight: bold;
}

#formContainer > #formMetadata {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 50px;
}

#formContainer > #formMetadata > div {
  margin-right: 30px;
}

#formContainer > #formMetadata > div > label {
  font-size: 14px;
  color: #000;
}

#formContainer > #formMetadata > div > input {
  font-size: 16px;
  padding: 5px;
}

#formContainer > #content {
  width: 650px;
  height: 330px;
  border: 1px solid #9d9d9d;
  margin: 10px;
  margin-left: 0px;
  background-color: #fff;
  color: #000;
  padding: 10px;
  box-sizing: border-box;
}

.formActions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.activeSidebarItem {
  border-left: 10px solid #3f99f7;
}

#plannedExposureContainer {
  display: flex;
  flex-flow: column nowrap;
  width: 50%;
  margin: 0 auto;
}

#plannedExposureContainer > * {
  text-align: left;
}

#plannedExposureBtnContainer {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 40px;
}

.pushRight {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.pushRight > input[type="submit"] {
  margin-left: 15px;
}

.wordLimit {
  display: block;
  text-align: left;
  font-size: 12px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.38);
}

.textInputContainer {
  width: 250px;
}

#surveyReviewModal > .modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* black w/ opacity */
}

/* Modal Content */
#surveyReviewModal > .modal > .modalContent {
  background-color: #fefefe;
  margin: auto;
  border-radius: 15px;
  height: 80%;
  max-height: 950px;
}

#twoWeekModal .modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0); /* fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* black w/ opacity */
}

/* Modal Content */
#twoWeekModal .modalContent {
  background-color: #fefefe;
  margin: auto;
  border-radius: 15px;
  height: 80%;
  width: 80%;
  max-width: 1000px;
  max-height: 550px;
  overflow-y: hidden;
}

#twoWeekModal .innerModalContent {
  width: auto;
  max-width: 450px;
  height: 100%;
  background-color: #fefefe;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 15px;
  min-height: 450px;
  text-align: center;
  margin: 0 auto;
}

@media only screen and (max-width: 1250px) {
  .textInputContainer {
    width: 100%;
  }

  .fs-Modal {
    width: 100%;
    height: 100%;
    max-width: none;
    border-radius: 0px;
    border: 0;
  }

  .mobileItemsContainer .fearLadderDetailsActions .submitBtnTransparent-auto {
    color: #a4a4a4;
    border: none;
    margin: 100px 0 40px 0 !important;
  }

  .formActions > input {
    flex: 50%;
    width: 50%;
    border-radius: 0;
  }
}

@media screen and (max-width: 950px) {
  .navItem {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
  }

  .wordLimit {
    text-align: left;
  }

  #formContainer > #formMetadata {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
  }

  #formContainer > #formMetadata > div {
    margin: 0 !important;
  }

  #formContainer > #formMetadata > div > label {
    font-size: 14px;
    color: #000;
  }

  #formContainer > #formMetadata > div > input {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .indexTitle {
    font-size: 23px;
  }

  .indexDescription {
    font-size: 15px;
  }
}

@media screen and (max-width: 700px) {
  .navItem {
    padding-left: 14px;
    padding-right: 14px;
    font-size: 11px;
  }

  .indexTitle {
    font-size: 20px;
  }

  .indexDescription {
    font-size: 12px;
  }

  #formContainer {
    width: 90%;
    margin: 0 auto;
    margin-top: 40px;
  }

  #formContainer > #content {
    width: 100%;
    height: 210px;
  }

  .custom-select {
    background-color: #fff;
    color: #3f99f7;
    padding: 5px;
    margin-right: 10px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .twoWeekReviewBody {
    width: auto;
  }
}
