.personaOuterContainer {
  margin: 40px 0px;
  cursor: pointer;
}

.persona {
  line-height: 1.6em;
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.persona p {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  margin: 20px 0;
}

.personaContainer {
  display: inline-flex;
  align-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: left;
  width: 100%;
}

.personaContainer img {
  width: auto;
  height: 79px;
}

.personaContentContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.personaText {
  display: inline-flex;
  justify-content: center;
  flex-flow: column nowrap;
  margin-left: 15px;
  width: 100%;
  font-size: 15px;
}

.personaText > span:first-child {
  font-size: 19px;
  font-weight: 600;
}

.personaToggleArrow img {
  width: 50px;
  min-width: 50px;
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -ms--display: flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms--justify-content: flex-end;
  align-items: center;
}

.personaDropdown {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.25s ease-out;
  -webkit-transition: max-height 0.25s ease-out;
  -moz-transition: max-height 0.25s ease-out;
  -o-transition: max-height 0.25s ease-out;
}

.personaDropdownActive {
  overflow: hidden;
  max-height: 5000px;
  transition: max-height 0.25s ease-in;
  -webkit-transition: max-height 0.25s ease-in;
  -moz-transition: max-height 0.25s ease-in;
  -o-transition: max-height 0.25s ease-in;
}

.personaDropdownActive > img {
  max-width: 680px;
}

@media screen and (max-width: 620px) {
  .personaDropdownActive > img {
    max-width: 335px;
  }
}
