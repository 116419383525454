.chatPractitioner {
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0px 4px 8px -2px rgba(191, 191, 191, 1);
}

.chatPractitioner-child {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
  box-sizing: border-box;
}

.chatPractitioner-name {
  font-weight: 600;
  margin-left: 15px;
}

.chatPractitioner-contactAction {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
