.profileContainer {
  display: flex;
  height: 100%;
  padding-top: 66px;
}

.profileBodyContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
}

.profileSubHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  min-height: 60px;
  border-bottom: 1px solid #cbcbcb;
  text-align: center;
}

.profileSubHeader > .profileSubHeaderHeading {
  height: 100%;
  max-width: 347px;
  min-width: 347px;
  background-color: #3f99f7;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profileSubHeader > .profileSubHeaderHeading > p {
  margin-left: 24px;
  font-size: 20px;
  color: #fff;
}

.bodyContainer > .center {
  margin-top: 50px;
}

.extrasNestedList {
  font-size: 14px;
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.2s ease-out;
}

.extrasNestedList-active {
  display: block;
  font-size: 14px;
  overflow: hidden;
  max-height: 500px;
  transition: max-height 0.2s ease-out;
}

#profileInfoContainer {
  -webkit-flex: 1;
  flex-flow: column;
  flex: 1;
  position: relative;
  margin-left: 20px;
  margin-top: 30px;
}

.profileSidebarItem {
  height: 100%;
  display: flex;
}

.profileSidebarItemContent {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.extrasHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  width: 100%;
  background-color: #fff;
  border-bottom: 2px solid #3f99f7;
}

.extrasBody {
  overflow: auto;
  height: calc(100% - 163px); /* account for header (50), footer (50), border */
  border-bottom: 1px solid #c9ced3;
  position: relative;
}

.extrasBox {
  display: flex;
  flex-direction: column;
  margin: 20px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 4px 8px -2px rgba(191, 191, 191, 1);
  padding: 0px;
  font-size: 15px;
  border: 2px solid #fff;
}

.extrasBox ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.extrasBox ul > li {
  border-bottom: 1px solid #e9ecef;
  padding: 10px 20px;
  box-sizing: border-box;
}

.active-list-item:active {
  background-color: rgba(191, 191, 191, 1);
}

.extrasBox ul > li:last-child {
  border-bottom: 0;
}

.extraCurrentSession {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  padding-bottom: 0px;
  cursor: pointer;
}

.passwordChange {
  border: 2px solid #3f99f7;
  border-radius: 8px;
  padding-bottom: 0px;
  margin-top: 30px;
}

.passwordChange .inputContainer {
  margin: 30px;
}

.passwordChange > button[type="submit"] {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

@media screen and (max-width: 1250px) {
  .profileSubHeader {
    align-items: center;
    justify-content: center;
  }

  .profileSubHeader > .profileSubHeaderHeading {
    display: none;
  }

  .messageModalContent {
    background-color: #fefefe;
    margin: 0 auto;
    padding: 17px;
    border: 1px solid #888;
    width: 66%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    border-radius: 8px;
  }
}
