.value-container {
  display: flex;
  box-shadow: 0px 3px 6px #00000017;
  border-radius: 8px;
  background-color: white;
  margin-top: 15px;
  min-width: 334px;
  height: 44px;
}

.value-selectable {
  cursor: pointer;
}

.value-active {
  border: 2px solid #3f99f7;
}

.value-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  border-top-left-radius: 6px; /* Is 6px rather than 8px since when active border shows there is a white space */
  border-bottom-left-radius: 6px;
}

.value-icon {
  color: white;
}

.value-name-arrow-container {
  display: flex;
  width: 100%;
  margin-left: 10px;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}

@media screen and (max-width: 1000px) {
  .value-container {
    width: 100%;
  }
}
