.subscribeHeader {
  width: 58%;
  top: 0;
  margin-left: 8%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribeHeader ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
}

.subscribeHeader ul li {
  float: left;
  width: 50%;
  text-align: center;
  cursor: pointer;
  margin: 10px 0px;
}

.subscribeHeader ul .active {
  border-bottom: 5px solid #3b9af7;
}

.subscribeHeaderLogo {
  width: 26%;
  margin: 15px 0px;
}

@media all and (max-width: 1000px) {
  .subscribeHeader {
    width: 58%;
    top: 0;
    margin-left: 0% !important;
    display: flex;
    flex-flow: column-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .subscribeHeader > h1 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .subscribeHeaderLogo {
    width: 110%;
  }
}
