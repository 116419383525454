.BEContainer {
  display: flex;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.BEDateContainer {
  width: 150px;
}

.BETitleContainer {
  width: 328px;
}

.BEDate {
  width: 100%;
  box-sizing: border-box;
}

.BETitle {
  width: 100%;
  box-sizing: border-box;
}

#recordExperimentModal {
  margin: 30px;
}

/* The Modal (background) */
#recordExperimentModal > .modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  box-sizing: border-box;
}

/* Modal Content */
#recordExperimentModal > .modal > .modalContent {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: column nowrap;
  text-align: center;
  background-color: #fefefe;
  border: 1px solid #888;
  height: auto;
  width: 50%;
  max-width: 1250px;
  margin: auto;
  border-radius: 10px;
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: auto;
}

.BEDetailsOutcome {
  border: 3px solid;
  border-radius: 11px;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 40px;
}

@media screen and (max-width: 1050px) {
  .BEDateContainer {
    width: 100%;
  }

  .BETitleContainer {
    width: 100%;
  }

  #recordExperimentModal > .modal > .modalContent {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  #recordExperimentModal .centerFlex {
    flex-flow: column nowrap !important;
    width: 100% !important;
  }

  #recordExperimentModal .centerFlex input {
    width: 100%;
  }
}
