.callback {
  margin: auto;
  height: auto;
  padding-bottom: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px -1px rgba(153, 153, 153, 1);
  box-sizing: border-box;
  border: none;
}

.callback p {
  padding: 20px;
}

.remainLoggedIn {
  display: flex;
  flex-flow: row nowrap;
  height: 40px;
}

.remainLoggedIn-yes,
.remainLoggedIn-no {
  width: 50%;
  border: none;
  cursor: pointer;
  margin: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.remainLoggedIn-yes {
  border-radius: 0px;
  border-bottom-right-radius: 10px;
  background-color: #3f99f7;
  color: #fff;
}

.remainLoggedIn-no {
  border-radius: 0px;
  border-bottom-left-radius: 10px;
  color: #a94442;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
}

.callbackForm .surveyLabel {
  width: auto;
}

.callbackForm {
  width: auto;
}

@media all and (max-width: 850px) {
  .callbackForm {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    justify-content: center;
  }
}
