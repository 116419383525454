.userDetailsNavItem h2 {
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 15px;
  color: #8a8a8a;
  text-decoration: none;
  font-weight: 400;
  margin: 0;
  height: 50px;
  transition: color 0.2s;
}

.userDetailsNavItem {
  text-decoration: none;
}

.userDetailsActiveNavItem {
  background-color: #25476a !important;
}

.userDetailsActiveNavItem h2 {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  background-color: #25476a !important;
}

.userDetailsNavItem h2:hover {
  color: #23527c;
}

.userDetailsActiveNavItem h2:hover {
  color: #fff;
}

.userDetailsHeader {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: #25476a;
  width: 100%;
  padding: 20px 0px;
}

.userDetailsHeader h2 {
  color: #fff;
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.userDetailsHeader h3 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0px;
}

.userDetailsHeader p {
  color: #96caff;
  font-size: 14px;
}

.userDetailsContainer {
  display: flex;
  flex-flow: row wrap;
  width: 90%;
  height: auto;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: 10px;
}

.userDetailsInfo {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
}

.userDetailsRightSide {
  display: flex;
  flex-flow: column nowrap;
  width: 50%;
  height: auto;
  min-height: 300px;
}

.userDetailsBoxesContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.userDetailsBoxesContainer > div:nth-child(1) {
  background-color: #8bc34a;
}

.userDetailsBoxesContainer > div:nth-child(2) {
  background-color: #03a9f4;
}

.userDetailsLoginActivity {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  background-color: #fff;
  margin: 10px;
  margin-bottom: 0px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 3px;
}

.userDetailsBox {
  flex: 1;
  background-color: blue;
  margin: 10px;
  margin-top: 0px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 3px;
  box-shadow: 5px 4px rgba(0, 0, 0, 0.25);
}

.userDetailsHeading {
  text-transform: uppercase;
  color: #4d627b;
  font-weight: 600;
  font-size: 13px;
  margin-left: 10px;
  margin-right: 10px;
}

.toolsAggregate {
  margin: 0 auto;
  margin-top: 50px;
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.toolsAggregate > div {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  height: 60px;
  width: 300px;
  padding: 15px;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 5px 4px rgba(0, 0, 0, 0.25);
}

.toolsAggregate > div > span:nth-child(1) {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}

.toolsAggregate > div > span:nth-child(2) {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.toolsAggregate > div:nth-child(1) {
  background-color: #ffb300;
}

.toolsAggregate > div:nth-child(2) {
  background-color: #03a9f4;
}

.toolsAggregate > div:nth-child(3) {
  background-color: #26a69a;
}

.toolsAggregate > div:nth-child(4) {
  background-color: #f44336;
}

.toolsAggregate > div:nth-child(5) {
  background-color: #ab47bc;
}

.userDetailsList {
  list-style-type: none;
  font-size: 13px;
  color: #909ba1;
}

.userDetailsList li {
  margin-top: 5px;
}

.personalProperty {
  font-weight: 600;
  color: #000;
}

.userDetailsLocation {
  list-style-type: none;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
}

.userDetailsLocation li {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.userDetailsLocation li span:nth-child(2) {
  font-weight: 700;
}

.addTag {
  font-size: 18px;
  cursor: pointer;
}

.tagContainer {
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-bottom: 10px;
}

.tagContainer p {
  padding: 5px;
  color: #fff;
  border-radius: 10px;
  margin: 5px;
}

.addNoteButton {
  height: 50px;
  width: 50px;
  background-color: #8bc34a;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: -28px;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  text-align: center;
}

.addNoteButton:hover span {
  transition-duration: 0.4s;
  transform: rotate(90deg);
}

.addNoteButton:not(:hover) span {
  transition-duration: 0.4s;
  transform: rotate(0deg);
}

.notePanel {
  width: 50%;
  height: auto;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 5px;
}

.noteHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.noteHeader span:nth-child(1) {
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #3a444e;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  border-bottom-right-radius: 10px;
}

.noteHeader span:nth-child(2) {
  position: relative;
  top: 10px;
  padding-right: 20px;
  font-size: 22px;
  cursor: pointer;
  transform: rotate(45deg);
}

.noteBody {
  font-size: 12px;
  color: #7a878e;
  margin: 10px;
  padding-bottom: 10px;
}

.noteTitle {
  font-size: 16px !important;
  display: inline;
  margin: 0px;
  margin-left: 10px;
}

.logList {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
}

.logList tbody tr {
  background-color: #fff;
  transition: background-color 0.07s ease-in;
}

.logList tbody tr:hover {
  background-color: rgb(194, 198, 203);
  color: #fff;
}

.logList td {
  font-size: 12px;
}

.userSurveysWrapper {
  padding-bottom: 40px;
  margin-top: 75px;
  margin-left: 75px;
}

@media only screen and (max-width: 1250px) {
  .userDetailsInfo,
  .userDetailsRightSide {
    width: 100%;
  }

  .userDetailsRightSide {
    margin-top: 15px;
  }

  .notePanel {
    width: 100%;
  }

  .userSurveysWrapper {
    padding-bottom: 40px;
    margin: 0px;
  }
}
