.FLContainer {
  display: flex;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.newFearLadderSidebarHeader {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 20px;
}

.exposureListItem {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  box-shadow: 0px 10px 15px -10px rgba(204, 204, 204, 1);
  margin-bottom: 25px;
  box-sizing: border-box;
  padding: 10px;
}

.exposureListItem > .header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.exposureListItem > .header > .indexItem {
  font-weight: 600;
}

.exposureListItem > .header > .link {
  font-weight: 400;
  font-style: normal;
}

.exposureRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.exposureRow > .exposureRowTitle {
  width: 15%;
  min-width: 60px;
  white-space: nowrap;
  box-sizing: border-box;
}

.fearRatingRow > .exposureRowTitle {
  width: auto;
  margin-right: 15px;
}

.exposureRow > .exposureRowBar {
  height: 30px;
  justify-self: flex-start;
  width: calc(100% - 15% - 40px);
}

.exposureRow > .exposureRowBar > div {
  height: 100%;
}

.exposureRow > .exposureRowRating {
  margin-left: 10px;
  flex-basis: 40px;
  white-space: nowrap;
  font-weight: 700;
  box-sizing: border-box;
}

.exposureRowIncomplete {
  font-weight: 700;
  height: 30px;
  color: red;
}

.planExposureBtn {
  margin-bottom: 20px;
  margin-right: 20px;
}

.fearLadderStepDetailsView {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 10px 15px -10px rgba(204, 204, 204, 1);
  margin-bottom: 25px;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
}

.fearLadderStepDetailsView > div {
  display: block;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  word-break: break-all;
}

.fearLadderStepDetailsView > div > span {
  margin-right: 20px;
}

.fearLadderStepDetailsViewReady {
  border: 3px solid #3f99f7 !important;
}

.sideBarItemContent > .anxietyTheme {
  display: block;
  margin-top: 10px;
}

.sideBarItemContent > .specificGoal {
  display: block;
  margin-top: 10px;
  color: #9d9d9d;
  margin-bottom: 10px;
}

.fearLadderStepDetails {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
}

.fearLadderStepDetailsLevel {
  display: flex;
  align-items: center;
  height: auto;
}

.fearLadderStepDetailsLevel > p {
  margin: 10px;
  margin-left: 0px;
}

.fearLadderDetailsActions {
  display: flex;
  flex-flow: column wrap;
  width: 95%;
  margin: 0 auto;
  align-items: center;
}

.fearLadderDetailsActions button {
  width: 100%;
  border-radius: 0px;
}

.fearLadderStepDetailsActions > p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.fearLadderStepDetailsCompleteContainer {
  display: flex;
  flex-flow: row nowrap;
  align-self: flex-end;
  height: 80%;
}

.fearLadderDetailsView-inactive > div {
  opacity: 0.2;
  pointer-events: none;
}

.fearLadderDetailsSubheader {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0px 5px 9px -7px rgba(186, 180, 186, 1);
  background-color: #fff;
}

.fearLadderPostCheck-openRetry {
  grid-template-rows: 1fr 2fr 1fr;
}

.postCheckDateContainer {
  width: 100%;
  display: flex;
  align-items: start;
}

.postCheckDateContainer label {
  margin: 0 auto;
}

.incompleteExposure {
  border: 2px solid #ffd834;
  border-radius: 8px;
  padding: 20px;
}

.createStepsActions {
  align-items: center !important;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
}

.fearLadderDetailsActions {
  margin-bottom: 20px;
}

@media screen and (max-width: 1250px) {
  .createStepsActions {
    flex-flow: column-reverse;
  }

  .createStepsActions .ToolActions {
    width: 100%;
  }

  .fearLadderStepDetails {
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap !important;
  }

  .fearLadderStepDetailsView {
    width: 95%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .fearRatingRow > .exposureRowTitle {
    margin-right: 32px;
  }

  .fearRatingRow > .exposureRowBar {
    width: calc(100% - 95px - 40px) !important;
  }

  .planExposureBtn {
    width: 100%;
  }
}
