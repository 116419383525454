.linkButton {
  font-size: 0.875rem;
  color: #3f99f7;
  cursor: pointer;
  font-weight: 400;
}

.linkButton:active {
  color: #0a5eb5;
}

@media not all and (hover: none) {
  .linkButton:hover,
  .linkButton:focus {
    color: #0a5eb5;
  }
}
