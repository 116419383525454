.thoughtJournalMobileItem {
  display: flex;
  flex-direction: column;
  margin: 20px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 4px 8px -2px rgba(191, 191, 191, 1);
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  cursor: pointer;
}

.thoughtJournalMobileItem > #createdAt {
  color: #8795a3;
  font-weight: 400;
}

.thoughtJournalMobileItem > #title {
  color: #000;
  font-weight: 400;
}

.thoughtJournalMobileItem > #autoThoughts {
  color: #8795a3;
  font-style: italic;
}

.thoughtJournalMobileItem > #situation {
  color: #8795a3;
}
