.infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0px 10px 15px -10px rgba(204, 204, 204, 1);
}

.BAinfoContainer {
  width: 725px;
  margin: 0 auto;
  margin-top: 30px;
  box-sizing: border-box;
}

.infoCircle {
  color: #3f99f7;
}

.infoContainer > div > p {
  color: #3f99f7;
  margin: 0px;
  font-size: 14px;
}

.currentWeekHeader {
  text-align: center;
  color: #173e5c;
  font-size: 15px;
  letter-spacing: 3px;
}

.activityLogInfoHeader {
  margin-left: 15px !important;
  margin-right: 15px !important;
  color: #3f99f7;
  text-align: center;
}

.weeklyRecordContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 10px 15px -10px rgba(204, 204, 204, 1);
}

.completedWeekContainer:hover {
  cursor: pointer;
}

.recordDate {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  padding-left: 20px;
  font-size: 15px;
}

.recordDate > p {
  font-weight: 600;
}

.completedDate:hover {
  cursor: pointer;
}

.recordMood {
  margin: 0;
  margin-right: 30px;
  font-size: 20px;
  color: #3f99f7;
  display: flex;
  align-items: center;
}

.moodFont {
  font-size: 14px;
  color: black;
  margin-right: 10px;
}

.desktopRecordActivityBtn {
  background-color: #3f99f7;
  color: #fff;
  padding: 6px;
  text-align: center;
  width: 200px;
  font-weight: 600;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  margin-top: -36px;
  border-bottom: 2px solid #3f99f7;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
}

.recordActivityBtn {
  background-color: #1fd077;
  color: #fff;
  padding: 10px;
  text-align: center;
  width: 100%;
  font-weight: 600;
}

.recordActivityBtn,
.desktopRecordActivityBtn:hover {
  cursor: pointer;
}

.activityLogInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  padding: 10px;
}

.activityLogInfo > div > p {
  color: #3f99f7;
  margin: 0px;
  font-size: 14px;
}

.activityRecordContainer {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
}

.activityRecordContainer > .inputContainer,
.activityRecordContainer > form > .inputContainer {
  margin-top: 10px;
}

.recordInput {
  width: 97%;
  height: 112px !important;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 3px;
  font-weight: 400;
  font-size: 16px;
  padding: 5px;
  resize: none;
}

.scaleLabelContainer {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
}

.scaleLabelContainer > div > div {
  font-size: 12px;
  font-weight: 600;
}

.activityCountContainer {
  background-color: #fff;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding: 20px;
  margin-top: 20px;
}

.activityCountContainer > p {
  font-size: 16px;
  font-weight: 600;
}

.counterContainer {
  display: flex;
  justify-content: space-around;
}

.activityCountActiveBtn {
  background-color: #3f99f7;
  color: #fff;
}

#clickableCounterContainer > span:hover {
  cursor: pointer;
}

.counterContainer > span {
  padding: 12px;
  border-radius: 25px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activityLogSaveBtn {
  background-color: #1fd077;
  color: #fff;
  border-radius: 7px;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.activityLogSaveBtn:hover {
  cursor: pointer;
}

.endOfWeekReviewContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-top: 2px #f6f6f6 solid;
}

.endOfWeekReviewContainer > div > p {
  margin: 0;
  font-size: 16px;
}

.endOfWeekTextContainer {
  background-color: #fff;
  padding: 20px;
  border-top: 2px #f6f6f6 solid;
}

.endOfWeekTextContainer > p {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

.endOfWeekLargeNumber {
  color: #3f99f7;
  font-weight: 600;
  font-size: 50px;
  margin: 0;
}

.completedDate {
  color: #7993af;
  font-size: 16px;
  margin: 0;
}

.completedDayDetailsContainer {
  display: flex;
  background-color: #fff;
  padding: 15px;
  border-top: 2px #f6f6f6 solid;
  flex-direction: column;
  border: 3px solid rgb(220 218 214 / 40%);
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 10px 15px -10px rgba(204, 204, 204, 1);
}

.completedDayDetailsContainer > p {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

.completedWeekSpacing {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.outOf10 {
  color: #7993af;
  font-size: 20px;
  margin-right: 10px;
  margin-left: 3px;
}

#editThisEntry:hover {
  cursor: pointer;
}

.endOfWeekModalBackground {
  position: absolute;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: gray;
  opacity: 0.6;
}

.endOfWeekModalContainer {
  position: absolute;
  width: 90vw;
  height: 100vh;
  left: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.endOfWeekModal {
  position: absolute;
  background-color: #fff;
  top: 80px;
  width: 360px;
  left: 25vw;
  height: 400px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
}

.endOfWeekModalParagraph {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.endOfWeekModalParagraph > p {
  margin: 0;
  font-weight: 600;
}

.endOfWeekModalBtn {
  background-color: #1fd077;
  color: #fff;
  padding: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 17px;
}

.endOfWeekModalBtn:hover {
  cursor: pointer;
}

@media screen and (max-width: 1050px) {
  .activityLogInfoHeader {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .endOfWeekModal {
    width: 100%;
    left: 0px;
  }

  .BAinfoContainer {
    width: 90%;
  }

  .BAValuesMainDiv {
    width: 90%;
  }

  .completedDayDetailsContainer {
    box-shadow: none;
    margin-bottom: 0px;
  }
}
