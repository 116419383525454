.invoiceLines {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}
.invoiceLines > div {
  color: #173e5c;
  font-weight: 400;
}
.billingHeader {
  text-align: left;
  font-size: 12px;
  letter-spacing: 1.68px;
  color: #7993af;
  text-transform: uppercase;
}
.changeBtnFont {
  color: #09709c;
  font-size: 15px;
  font-weight: 400;
}
.cancelAccountFont {
  color: #a71226;
  font-size: 15px;
}
.changeBtnFont,
.cancelAccountFont:hover {
  cursor: pointer;
}
.billingDivider {
  border-top: solid #ccd9e8 1px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
}

.billingFont {
  color: #173e5c;
  font-weight: 400;
  margin-bottom: 5px;
}

@media screen and (max-width: 1050px) {
  .billingHeader {
    text-align: center;
  }
  .billingHeader > span::before {
    content: "\A";
  }
}
