.modal-window {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}
.modal-window * {
  box-sizing: border-box;
}
.modal-window.inactive-modal {
  transition: height 0s 0.3s, opacity 0.3s;
  height: 0;
  opacity: 0;
}
.modal-window .modal-wrapper {
  padding: 30px 0;
  min-height: 100%;
}
.modal-window .modal {
  position: inherit;
  margin-top: 70% !important;
  margin: 0 auto;
  z-index: 500;
  background-color: white;
  width: 90%;
  border: 2px solid #3f99f7;
  border-radius: 7px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  font-family: Arial;
  cursor: initial;
  transform: translateY(-100%);
  opacity: 0;
}

.modal-window .modal * {
  box-sizing: border-box;
}
.modal-window .modal.animate-modal {
  display: block;
  transform: translateY(0);
  opacity: 1;
}
.modal-window .modal .modal-header {
  position: relative;
  padding: 15px;
  font-weight: 300;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.modal-window .modal .modal-header .modal-title {
  margin: 0;
  line-height: 1.42857;
  text-transform: capitalize;
}
.modal-window .modal .modal-header .close-modal {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  outline: none;
  opacity: 0.2;
  transition: opacity 0.15s;
}
.modal-window .modal .modal-header .close-modal:hover {
  opacity: 0.9;
}
.modal-window .modal .modal-body {
  position: relative;
  text-align: left;
}
.modal-window .modal .modal-footer {
  padding: 10px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-window .modal .modal-footer .close-modal {
  color: #212529;
  background-color: transparent;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid #212529;
  padding: 0.175rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
  text-transform: capitalize;
}
.modal-window .modal .modal-footer .close-modal:hover {
  color: #212529;
  background-color: #eff2f5;
  border-color: #dae0e5;
}
@media (min-width: 768px) {
  .modal-window .modal {
    width: 600px;
  }
}
.emptyBoxContainer {
  max-width: 100%;
  overflow: auto;
  text-align: center;
  white-space: nowrap;
}
.emptybox.first {
  margin-left: 0px !important;
}
.emptybox.last {
  margin-right: 0px;
}
.emptybox img {
  width: 109%;
  margin-top: 7px;
}
.emptybox {
  vertical-align: bottom;
  margin-left: 5px;
  margin-right: 5px;
  width: 17%;
  height: 60px;
  background-color: #e9ecef;
  border-radius: 7px;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
}

#modalFooter {
  margin: 0;
  text-align: center;
  padding: 13px;
  background: #e9ecef;
  color: #565656;
}

#modalFooter img {
  width: 24px;
  vertical-align: bottom;
}
