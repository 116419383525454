.crisis-msg {
  text-align: center;
  width: 640px;
  margin: 15px auto;
  color: #959595;
  height: auto;
  font-size: 12px;
}

.crisis-msg-link {
  font-size: 12px;
  font-style: italic;
  color: #09709c;
  cursor: pointer;
}

.tjNewEntry {
  color: #3f99f7;
  font-weight: 600;
  cursor: pointer;
}

.fearLadderBox {
  padding: 0px !important;
}

.fearLadderBox .boxHeader {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
}

.fearLadderBox p {
  font-size: 17px;
  color: #000;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
}

.fearLadderBox:hover,
.fearLadderBox:focus {
  border-color: #1fb268 !important;
}

.fearLadderBox .link-btn {
  background-color: #1fd077;
  color: #fff;
  font-size: 16px;
  border: none;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fearLadderBox .link-btn:active {
  background-color: #1fb268;
}

.fearLadderBox .link-btn:focus {
  border: 3px solid red;
}

.experimentCompleteDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  background-color: #1fd077;
  color: #fff;
  padding: 10px;
}
.experimentYesNo {
  display: flex;
  width: 85px;
  justify-content: space-between;
  align-items: center;
}

@media not all and (hover: none) {
  .fearLadderBox .link-btn:hover,
  .fearLadderBox .link-btn:focus {
    background-color: #1fb268;
  }
}

.boxShadow {
  filter: drop-shadow(0px 2px 1px rgba(20, 20, 19, 0.28));
}

.gad7Chart * {
  cursor: pointer;
}

.gad7Chart .fs-Modal {
  width: 100%;
}

.gad7Chart .modal {
  cursor: context-menu;
}

.gad7Chart .fs-ModalContent {
  height: 90%;
}

.boxHeader {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2.64px;
  color: #7993af;
}

.gad7Chart .close {
  margin-right: 10px;
}

.gad7Chart .modal {
  align-items: center;
}

.gad7Chart .modalContent {
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding: 0;
}

.gad7Modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  background-color: #fff;
  width: 100%;
  height: 90%;
  margin: auto auto;
  cursor: pointer;
  padding: 40px;
  padding-top: 0px;
  box-sizing: border-box;
}

.gad7Modal * {
  cursor: pointer;
}

.gad7Modal > span {
  color: grey;
  font-size: 20px;
}

.gad7Modal .modal {
  cursor: context-menu;
}

.practitionerContactDiv {
  color: rgb(63, 153, 247);
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  flex-wrap: wrap;
}

@media screen and (max-width: 1300px) {
  .gad7Chart .fs-ModalContent {
    height: 650px;
    width: 750px;
    padding: 0;
    margin: auto;
  }
}

@media all and (max-width: 1250px) {
  .crisis-msg {
    width: auto;
    padding: 16px;
    font-size: 12px;
  }

  .gad7Chart {
    min-width: 0px;
  }

  .gad7Chart > span {
    font-weight: 400;
    font-size: 15px;
    text-align: left;
    width: 100%;
    color: #939393;
  }
}

@media screen and (max-width: 850px) {
  .gad7Modal > span {
    font-size: 12px;
  }

  .gad7Chart .modalContent {
    width: 100%;
  }

  .gad7Modal {
    padding: 10px;
  }
}

@media screen and (max-width: 800px) {
  .gad7Chart .fs-ModalContent {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 700px) {
  .gad7Chart .fs-ModalContent {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    margin-top: 30px;
  }

  .gad7Chart .fs-ModalContent > .gad7Modal {
    height: 250px;
    width: 100%;
    min-width: 300px;
  }
}
