/* Sub Header */
.ba-subheader {
  display: flex;
  justify-content: space-around;
  height: 50px;
  box-shadow: 0px 0px 5px 2px rgba(100, 100, 100, 0.2);
}

.ba-subheader-section {
  display: flex;
  width: 33%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Activation Card */
.activation-card-container {
  padding: 10px 20px;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 10px;
  background-color: white;
  margin: 20px 0px;
}

.font-16 {
  font-size: 16px;
}

.bold-text {
  font-weight: 600;
}

.add-activity-container {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

@media screen and (min-width: 1250px) {
  .activation-section {
    border-bottom: 0;
  }
}
