.usersTableToggle {
  list-style-type: none;
  display: flex;
}

.clientRed {
  background-color: red;
  padding: 6px;
  border-radius: 10px;
  white-space: nowrap;
  color: #fff;
}

.clientGrey {
  background-color: rgb(200, 197, 197);
  padding: 6px;
  border-radius: 10px;
  white-space: nowrap;
  color: #000;
}

.subscriptionType {
  display: block;
  background-color: #d7f5ea;
  border-radius: 10px;
  padding: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.userList #actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.userList #actions > * {
  padding: 5px;
}

.userLink {
  cursor: pointer;
}
