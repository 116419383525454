.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(157, 157, 171);
  height: 100%;
}

.logos {
  display: flex;
  flex-direction: row;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  box-shadow: 0px 0px 10px 2px rgba(100, 100, 100, 0.6);
  background-color: white;
  border-radius: 10px;
}

.content h2 {
  margin-bottom: 0px;
}
.content a {
  text-decoration: none;
  color: #3b70ea;
}

.logoContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.browserLogo {
  height: 100px;
  width: auto;
}
