.circle {
  border-radius: 50%;
  object-fit: cover;
  width: 35px;
  height: 35px;
}

.pointer {
  cursor: pointer;
}
