.logoutButtonDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 5%;
  margin-right: 9%;
}

.promoCodeDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 5%;
  margin-right: 9%;
  width: 65%;
  padding-bottom: 8%;
}

.promoCodeHeader {
  color: #173e5c !important;
  text-align: start !important;
  font-weight: 600;
  font-size: 20px !important;
  margin-bottom: 0 !important;
}

.promoInput {
  height: 22px !important;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  background-color: #f6f6f6 !important;
  border: 1.5px solid #d9d9d9 !important;
  width: 70% !important;
  padding: 5px !important;
}

.errorPromoInput {
  height: 22px !important;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  background-color: #f6f6f6 !important;
  border: 1.5px solid rgb(231, 25, 25) !important;
  width: 70% !important;
  padding: 5px !important;
}

.correctPromoInput {
  height: 22px !important;
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
  background-color: #f6f6f6 !important;
  border: 1.5px solid #1fd077 !important;
  width: 70% !important;
  padding: 5px !important;
}

.applyCodeButton {
  background-color: #3f99f7;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 7px;
  padding-left: 12px;
  padding-right: 12px;
}

.applyCodeButton:hover {
  cursor: pointer;
}

.errorCodeButton {
  background-color: rgb(231, 25, 25);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 7px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: -3%;
}

.correctCodeButton {
  background-color: #1fd077;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 7px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: -3%;
}

.promoMessage {
  color: #1fd077;
  margin: 0;
}

.errorPromoMessage {
  color: rgb(231, 25, 25);
}

.cancelPromoCode {
  content: "x";
  font-size: 20px;
  font-family: sans-serif;
  color: #8795a3;
  position: absolute;
  top: 3px;
  right: 5px;
}

.cancelPromoCode:hover {
  cursor: pointer;
}

/* Modal background */
.messageModal {
  position: fixed;
  z-index: 1005;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.messageModalContent {
  position: relative;
  background-color: #fefefe;
  margin: 15% auto;
  padding: 12px;
  border: 1px solid #888;
  width: 20%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  border-radius: 8px;
}

/* Cancel Button */
.confirmLogout {
  background-color: #3f99f7;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 13px;
  padding-right: 27px;
  padding-left: 27px;
}

.confirmLogout:hover,
.confirmLogout:focus {
  background-color: #6fb4fc;
  text-decoration: none;
  cursor: pointer;
}

.practitionerIconContainer {
  width: 40px;
  height: 40px;
  align-content: center;
  display: flex;
  justify-content: center;
  border-radius: 100px;
  background-color: #d9f0fa;
}

.practitionerLogoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
}

/* Close logout button for Practitioner/admin/supervisor*/
.closeLogoutPractitioner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  color: #869191;
}

.closeLogoutPractitioner:hover {
  background: #24262608;
}

.closeLogoutPractitioner:active {
  background: #2426260f;
}

/*Wrapper for Practitioner's logout/cancel buttons*/
.practitionerLogoutButtonWrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

/* Confirm logout button for Practitioner/admin/supervisor*/
.confirmLogoutPractitioner {
  border-radius: var(--component-button-border-radius-pill, 999px);
  background: var(--alias-color-bg-primary, #00544e);
  color: var(--alias-color-text-on-primary, #d9f0fa);
  font-size: 15px;
  display: flex;
  height: var(--component-button-height-sm, 40px);
  padding: 0px var(--component-button-sm-padding-base, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--component-button-space, 8px);
  align-self: stretch;
  width: 50%;
}

.confirmLogoutPractitioner:hover {
  background: #004645;
}
.confirmLogoutPractitioner:active {
  background: #004645;
}

/* Cancel button for Practitioner/Admin/Supervisor */
.cancelLogoutPractitioner {
  border-radius: var(--component-button-border-radius-pill, 999px);
  border: 1px solid var(--alias-color-border-base, #d0dbdb);
  display: flex;
  height: var(--component-button-height-sm, 40px);
  padding: 0px var(--component-button-sm-padding-base, 20px);
  justify-content: center;
  align-items: center;
  gap: var(--component-button-space, 8px);
  width: 50%;
  align-self: stretch;
  color: var(--alias-color-text-on-outline, #545959);
  font-size: 15px;
}

.cancelLogoutPractitioner:hover {
  background: #24262608;
}

.cancelLogoutPractitioner:active {
  background: #2426260f;
}

/* Cancel Button */
.cancelLogout {
  color: #3f99f7;
  font-size: 12px;
  font-weight: 600;
}

.cancelLogout:hover,
.cancelLogout:focus {
  color: #6fb4fc;
  text-decoration: none;
  cursor: pointer;
}

.navButtonDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 3%;
  margin-right: 3%;
}

.cancelNav {
  color: #3f99f7;
  border: 1px solid #3f99f7;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  padding: 13px;
  padding-right: 27px;
  padding-left: 27px;
}
.cancelNav:hover,
.cancelNav:focus {
  color: #6fb4fc;
  text-decoration: none;
  cursor: pointer;
}
.confirmNav {
  background-color: #3f99f7;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 13px;
  padding-right: 27px;
  padding-left: 27px;
}
.confirmNav:hover,
.confirmNav:focus {
  background-color: #6fb4fc;
  text-decoration: none;
  cursor: pointer;
}

.practitionerHeading {
  font-size: 19px;
  font-weight: 600;
}

.practitionerMessageCopy {
  margin-bottom: 17px;
  font-size: 17px;
  font-weight: 500;
  color: #545959;
}

.messageCopy {
  margin-bottom: 17px;
}
