@charset "utf-8";

.signupMainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #eaeff4;
}

.loginTop {
  position: absolute;
  top: 27px;
  right: 15px;
  color: #fff;
  text-decoration: none;
}

.loginTop:hover {
  cursor: pointer;
}

.promoCodeMsg {
  color: #173e5c;
  padding-top: 20px;
}

#signup-logo {
  height: auto;
  width: 200px;
}

.accountSetupMainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 3%;
}

#signupBtn {
  display: flex;
  justify-content: space-around;
  background-color: #3f99f7;
  border: 1px solid #3f99f7;
  color: #ffff;
  height: 50px;
  margin-top: 20px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}

.loginForm {
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 5px;
  width: 500px;
  box-shadow: 0px 4px 5px 1px rgba(212, 212, 212, 1);
}

.signupFormContainer {
  position: relative;
  min-width: 750px;
  max-width: 890px;
  display: flex;
  flex-flow: wrap;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  margin-top: 20px;
}

.accountSetupContainer {
  position: relative;
  min-width: 250px;
  max-width: 500px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
}

.signupForm-aside {
  background-size: cover;
  width: 40%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.signupForm-aside .desktopImg {
  width: 80%;
  margin-left: -30px;
}

.signupForm-aside .logoImg {
  width: 80%;
}

.signupForm-aside p,
.signupForm-aside a {
  font-weight: 400;
  font-size: 14px;
  color: #173e5c;
  width: 80%;
  text-align: left;
}

.signupFormLogo {
  margin-top: 30px;
  width: 150px;
}

.fillerHeader {
  margin-bottom: 0px;
  font-size: 2px;
}

.accountSetupFillerHeader {
  margin-bottom: 0px;
  font-size: 30px;
}

.signupHeader {
  margin-bottom: 3%;
  color: #173e5c;
  font-size: 30px;
  font-weight: 600;
}

.signupHeaderDescription {
  display: inline-block;
  width: 160px;
}

.signupForm {
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  width: 60%;
  padding: 0px 30px;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.signupForm select {
  min-height: 30px;
}

.signupForm > form {
  width: 60%;
}

.signupForm-inputs {
  height: 100%;
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
}

.signup-name-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.signup-name-container > div {
  width: 45%;
}

.signup-name {
  padding: 15px !important;
  box-sizing: border-box;
}

.textField-signup {
  border: none;
  background-color: #f6f6f6;
  border: 1px solid #c4c4c4;
  width: 100%;
  height: 20px !important;
  padding: 5px 0px;
  border-radius: 5px;
  padding: 15px !important;
  box-sizing: border-box;
  resize: none;
  font-weight: 400;
}

.textField-signupError {
  border: none;
  background-color: #f6f6f6;
  border: 1px solid #ad645c;
  width: 100%;
  height: 20px !important;
  padding: 5px 0px;
  border-radius: 5px;
  padding: 15px !important;
  box-sizing: border-box;
}

.setupAccountForm {
  display: flex;
  flex-flow: column nowrap;
  background-color: #fff;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 5%;
}

.locationInputs {
  display: flex;
  justify-content: space-between;
}

.select-form {
  display: flex;
  flex-flow: column;
  width: 50%;
}

.select-form > select {
  width: 100%;
  background-color: white;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  box-sizing: border-box;
}

.signupRadioOptionGroup .radioOption {
  margin-bottom: 6px;
}

.signupRadioOptionBtn {
  margin-right: 5px;
}

.signupRadioOptionBtn > div {
  border: 1px solid #d0dbdb;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
}

.signupRadioOptionBtn > div[data-checked="false"]:hover {
  background-color: var(--alias-state-base-hover, rgba(#000000, 0.04)) !important;
}

.signupRadioOptionBtn > div[data-checked="false"] {
  background-color: #ffffff;
}

.signupRadioOptionBtn > div[data-checked="true"] {
  background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27white%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3ccircle cx=%278%27 cy=%278%27 r=%273%27/%3e%3c/svg%3e");
  border-color: var(--alias-color-border-active, #00544e);
}

.loginBottomDiv {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.inlineError {
  color: red;
  font-size: 15px;
  font-weight: 400;
}

.loginForm > form {
  width: 80%;
  margin-left: -25px;
}

#loginBtn {
  display: flex;
  justify-content: space-around;
  margin-right: 24px;
  background-color: #3f99f7;
  border: 1px solid #3f99f7;
  color: #ffff;
  height: 30px;
  width: 130px;
  border-radius: 6px;
  cursor: pointer;
}

.policiesContainer {
  min-height: 100vh;
  width: 100%;
  padding: 20px 58px;
  box-sizing: border-box;
  background-color: #89959f;
  display: flex;
  flex-direction: column;
}

.policiesSubContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 1%;
  border-radius: 8px;
  padding-left: 6%;
  padding-right: 6%;
  margin-right: 10%;
  margin-left: 10%;
}

.policiesLogo {
  width: 150px;
  position: relative;
  height: auto;
}

.policiesScrollableContainer {
  position: relative;
  padding: 20px;
  border: 1px solid #c9ced3;
  background-color: #f5f6f7;
  color: #000;
  height: 58vh;
  overflow: scroll;
}

.policiesAcceptContainer {
  margin-top: 15px;
}

.writtenAgree {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
}

.writtenAgree > p {
  letter-spacing: normal;
  line-height: normal;
}

.writtenAgree > input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-color: transparent;
}

.writtenAgree > input[type="checkbox"]::-ms-check {
  display: none;
}

.writtenAgree > input[type="checkbox"] {
  cursor: pointer;
  position: relative;
  width: 15px;
  min-width: 15px;
  height: 15px;
  margin-right: 20px;
}

.writtenAgree > input[type="checkbox"]::before {
  content: "✔";
  position: absolute;
  font-size: 12px;
  text-align: center;
  border: 2px solid #dcdee8;
  background-color: #fff;
  border-radius: 5px;
  color: transparent;
  transition: 0.1s;
  padding: 2px 4px;
}

.writtenAgree > input[type="checkbox"]:checked::before {
  /* Use `visibility` instead of `display` to avoid recalculating layout */
  color: rgb(52, 58, 84);
  border-color: rgb(52, 58, 84);
  background-color: #f0f2fa;
}

.policyErrorMessage {
  display: flex;
  align-self: center;
  width: 100%;
  height: 4%;
  background-color: rgb(255, 118, 144);
  border-radius: 5px;
  color: #fff;
  padding: 5px;
  margin-top: 7px;
  justify-content: center;
}

.policyAgreeButtons {
  padding: 16px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.policyAgreeButtons > input {
  margin-right: 10px;
  font-weight: 600;
}

.policiesContainer h1 {
  display: flex;
  color: #000;
  font-size: 21px;
  letter-spacing: 0.01em;
  line-height: 1em;
  font-weight: 600;
}

.policiesContainer h2 {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.06em;
  line-height: 1.3em;
  margin-top: 3%;
}

.policiesContainer p {
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.06em;
  line-height: 1.3em;
}

@media screen and (max-width: 700px) {
  .signupFormContainer {
    min-width: 250px;
  }

  .signupForm {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
  }

  .signupForm-aside {
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
  }

  .signupForm {
    width: 100%;
    padding: 15px;
  }

  .promoCodeMsg {
    color: #173e5c;
    padding-top: 0px;
  }
}

@media screen and (max-width: 960px) {
  .writtenAgree {
    justify-content: space-around;
  }

  .writtenAgree > p {
    width: 70%;
  }

  .policyAgreeButtons > input {
    margin-right: 10px;
    font-weight: 600;
    margin-top: 3px;
  }
}

@media screen and (max-width: 820px) {
  .setupAccountForm {
    padding: 20px;
  }
}

@media screen and (min-width: 820px) {
  .policiesAcceptContainer {
    width: 83%;
  }

  .policyAgreeButtons {
    flex-flow: row nowrap;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 620px) {
  .policiesContainer {
    padding: 10px;
  }

  .policiesSubContainer {
    margin-right: 0px;
    margin-left: 0px;
  }
}
