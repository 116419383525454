.twoWeekReview .modalContent {
  padding: 0px;
}

.modalInnerContainer {
  width: 100%;
  margin: 0 auto;
}

.survey {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  margin: 0 auto;
  max-width: 950px;
}

.survey > div {
  margin: 0 auto;
}

.contentView .survey {
  max-width: none;
}

.contentView .survey > div {
  width: 100%;
}

.survey header {
  position: sticky;
  top: 0px; /* Size of header*/
  border: 1px solid lightgrey;
  background-color: #fff;
  box-shadow: 0px 5px 9px -7px rgba(186, 180, 186, 1);
}

.surveyRow {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin: 0px;
  margin-bottom: 40px;
  align-items: flex-start;
}

.surveyLabel {
  display: flex;
  align-self: flex-start;
  flex-direction: column-reverse;
  width: auto;
  margin: 0;
  margin-bottom: 20px;
}

.surveyTitle > p {
  font-size: 20px;
  margin: 20px 0px;
  font-weight: 600;
  color: #0b2336;
}

.surveyLabel > p {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  color: #0b2336;
}

.yesNoRadioOptionGroup .radioLabel {
  width: 40px;
  text-align: left;
}

.yesNoRadioOptionGroup .radioOptionBtn > div {
  height: 20px !important;
  width: 20px !important;
}

@media all and (max-width: 1250px) {
  .surveyRow {
    max-width: none;
  }

  .survey {
    width: 100%;
  }

  .survey > div {
    width: 100%;
    margin: 0 auto;
  }

  .survey section {
    margin: 0 auto;
    width: 90%;
  }

  .contentView .survey section {
    width: 100%;
  }

  .survey footer {
    margin: 0 auto;
    width: 90%;
  }

  .survey header {
    position: sticky;
    top: 50px; /* Size of header*/
  }

  .yesNoRadioOptionGroup {
    flex-flow: row;
    align-items: center;
    display: flex;
    width: 350px;
    margin-left: 35px;
  }

  .finalSurveyRow .radioOptionBtn > div {
    width: 85px !important;
    height: 55px !important;
  }

  .surveyLabel > .errorMessages {
    margin-left: 18px !important;
  }
}

#dobMonth {
  border-radius: 0px;
}

#dobYear {
  width: 80px;
}
#dobDay {
  width: 50px;
}
#btnSubmit {
  color: #fff;
  background-color: #3f99f7;
  padding: 15px;
  padding: 15px 33px;
  outline: none;
  border: none;
  font-size: 16px;
  margin-top: 10px;
}

@media only screen and (max-width: 400px) {
  .smallSurveyLabel {
    line-height: 12px;
  }

  .smallLabelFont {
    font-size: 9px;
  }
}
