html,
body {
  margin: 0px;
  height: 100%;
}

.onboardingContainer {
  flex: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #eaeff4;
}

.headerContainer {
  position: relative;
  height: 60px;
  padding: 10px 0px;
  background-color: #173e5c;
}

.headerContainer .logo-wrapper {
  display: block;
  width: 150px;
  height: 100%;
  margin: 0 auto;
}

.subheaderContainer {
  height: auto;
  background-color: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
}

.subHeader {
  flex-flow: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subHeaderStep2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.onboardingHeader {
  display: flex;
  box-sizing: content-box;
}

.onboarding-select-lang {
  margin-top: 15px;
  position: absolute;
  right: 118px;
}

.onboarding-select-lang:hover {
  cursor: pointer;
}

.selectedLang {
  background: transparent;
  border: none;
  padding: 0px;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
}

.unselectedLang {
  background: transparent;
  border: none;
  padding: 0px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.onboardingTitle {
  font-weight: 400;
  color: #0b2336;
  font-size: 16px;
  text-align: left;
  align-self: flex-start;
}

.onBoardingBoldFont {
  font-weight: 400;
  color: #0b2336;
}

.onBoardingRegularFont {
  font-weight: 400;
  color: #0b2336;
}

.onBoardingLightFont {
  font-weight: 400;
  color: #0b2336;
  font-size: 15px !important;
}

.capitalize {
  text-transform: capitalize;
}

.valuesWrapper {
  height: 100%;
}

.valuesMainDiv {
  display: flex;
  align-items: center;
  padding-top: 30px;
  width: 725px;
  margin: 0 auto;
  background-color: #eaeff4;
  flex-flow: column nowrap;
}

.BAValuesMainDiv {
  background-color: #fff;
  padding-top: 0px;
  margin-top: 30px;
}

.valuesContainer {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.wrongValSymp {
  width: 725px;
  margin: 0 auto;
  margin-top: 30px;
}

.wrongValSymptoms {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.wrongValSymptoms .symptoms {
  cursor: auto;
}

.secondColumn {
  margin-left: 20px;
}

.contentContainer {
  width: 725px;
  max-width: 725px;
}

.symptoms {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  margin-left: 0px;
  display: flex;
  text-align: left;
  justify-content: space-between;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 18px;
  min-width: 334px;
  max-width: 334px;
}

.symptom {
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  width: 100%;
}

.iconContainer {
  height: 45px;
  width: 45px;
  padding-right: 42px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #ff7690;
}

.valuesInnerDiv {
  display: flex;
  padding: 10px;
}

.valueLabel {
  width: 100%;
  font-size: 15px;
  margin: 10px;
  white-space: nowrap;
}

.symptomLabel {
  width: 90%;
  margin: 0px;
}

.symptomLabel-padding {
  width: 90%;
  margin: 0px;
  padding: 10px;
}

.secondColumn > .symptoms {
  margin-bottom: 17px;
}

.onboardingCircle {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.unselectedCircle {
  background: #eaeff4;
}

.selectedCircle {
  background: #19a860;
}

.symptoms,
.reSelectValues,
.unselectedCircle,
.selectedCircle:hover {
  cursor: pointer;
}

.continueBtnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  width: 250px;
  font-weight: 400;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
}

.onboardingContinueBtn {
  width: 200px;
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;
}

.onboardingContinueBtn > button {
  font-weight: 600;
}

.numSelected {
  text-align: center;
  margin: 10px 0px;
  font-size: 16px;
}

.reviewValSympContainer {
  margin-bottom: 20px;
}

.reviewValSymp {
  width: 725px;
  margin: 0 auto;
}

.valSympReview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.valSympReview .symptoms {
  cursor: auto;
}

.valSympReviewHeader {
  margin: 30px 0px;
  font-size: 16px;
}

.confirmReviewContainer {
  margin-top: 80px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.yesNoBtnContainer {
  display: flex;
  width: 300px;
  /* margin-left: 35px; */
  justify-content: space-around;
}

.valSympBtn:hover {
  cursor: pointer;
}

.valSympBtnContainer {
  display: flex;
  width: 256px;
  margin-top: 12px;
  justify-content: space-between;
}

.valSympBtn {
  width: 92px;
  height: 13px;
  background-color: #3f99f7;
  color: #fff;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 9px;
  border: 2px solid #3f99f7;
  box-shadow: 0px 3px 6px #00000017;
}

.valSympBtn:focus-visible {
  background: #fff;
  color: #3f99f7;
}

.diagnosisWrapper {
  height: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #eaeff4;
}

.diagnosisContainer {
  background-color: #fff;
  width: 50%;
  padding: 15px 40px;
  border-radius: 8px;
  max-width: 725px;
}

.diagnosisContainer .survey > div {
  width: 100%;
}

.diagnosisContainer .surveyRow {
  max-width: none;
}

.diagnosisContainer .survey section,
.diagnosisContainer .survey main {
  margin: 0px;
  width: 100%;
}

.diagnosisDefinition {
  margin-bottom: 0px;
  margin-top: 30px;
}

.suicideContainer {
  padding: 70px;
}

.helpInformationList {
  list-style: none;
  padding-left: 15px;
  font-size: 14px;
}

.helpInformationList li::before {
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.onboardingSurveyContainer {
  padding-top: 30px;
  margin-bottom: 30px !important;
  width: 90%;
}

.surveyBlueLine {
  border: rgb(63, 153, 247) 1px solid;
  background-color: rgb(63, 153, 247);
  width: 165px;
  margin-top: 50px;
}

.diagnosisBtnContainer {
  display: flex;
  flex-flow: column;
}

.diagnosisYesNoBtnContainer {
  display: flex;
  width: 275px;
  margin-top: 20px;
  justify-content: space-between;
}

.suicideWrapper {
  height: 91%;
  justify-content: center;
}

.disorderDiv {
  margin-top: 30px;
}

.disorderBtnContainer {
  margin-top: 10px;
}

.diagnosisContainer a {
  color: #4387fe;
}

.diagnosisSurvey {
  padding: 10px 30px !important;
}

.pleaseNoteFont {
  color: rgb(33, 88, 129);
  padding-bottom: 20px;
  padding-top: 10px;
}

.getStartedBtnContainer {
  display: flex;
  width: 275px;
  margin-top: 20px;
  justify-content: space-between;
}

.introImgContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.introImg {
  width: 65%;
}

@media screen and (max-width: 1000px) {
  .stepContainer {
    background-color: #eaeff4;
  }

  .onboarding-select-lang {
    margin-top: 16px;
    left: 16px;
  }

  .subHeader {
    width: 90%;
    max-width: none;
    align-self: flex-start;
  }

  .valuesMainDiv {
    text-align: center;
    width: 90%;
  }

  .valuesContainer {
    flex-flow: column;
    align-items: center;
    width: 100%;
  }

  .valuesContainer > div {
    width: 100%;
  }

  .secondColumn {
    margin-left: 0;
  }

  .values {
    margin-bottom: 0;
    margin-right: 0px;
  }

  .reviewValSymp {
    width: 90%;
  }

  .valSympReview {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .subHeaderStep2 {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .subHeaderStep2 .onboardingTitle {
    align-self: center;
  }

  .wrongValSympContainer {
    margin-top: 10px;
  }

  .wrongValSymp {
    width: 90%;
  }

  .wrongValSymptoms {
    flex-flow: column;
    width: 100%;
    max-width: 100%;
    justify-content: center;
  }

  .symptoms {
    width: 100%;
    max-width: none;
  }

  .confirmReviewContainer {
    margin-top: 15px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    flex-flow: column;
  }

  .reviewSecondColumn {
    margin-top: 20px;
  }

  .valuesWrapper {
    background-color: #ffff !important;
  }

  .diagnosisContainer {
    background-color: #fff;
    width: 85%;
    padding: 15px;
    border-radius: 8px;
  }

  .suicideContainer {
    padding: 15px;
  }

  .onboardingContinueBtn {
    align-self: center;
  }

  .pleaseNoteFont {
    color: rgb(33, 88, 129);
    padding-bottom: 20px;
    padding: 10px;
    margin-left: 10px;
  }

  .getStartedBtnContainer {
    align-self: center;
  }

  .onboardingSurveyContainer {
    width: 90%;
  }

  .suicideWrapper {
    justify-content: flex-start;
  }

  .frenchIntroImg {
    width: 100%;
  }

  .reviewAnswers {
    width: 100vw !important;
    margin: 0 !important;
    padding: 3.75rem 1.5rem !important;
  }
  .reviewAnswers__list {
    width: 100% !important;
  }
  .resultsRow {
    padding: 1.5rem 0 !important;
  }
  .resultsRow--first {
    padding: 0 0 1rem 0 !important;
  }
  .resultsRow__question {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .resultsRow__answer {
    display: none !important;
  }
  .resultsRow__answer--mobile {
    display: block !important;
    margin-top: 1rem;
    color: #123c5a;
    font-weight: 600;
  }
  .reviewAnswers__button {
    width: 100%;
  }
}

@media screen and (min-width: 1250px) {
  .onboardingSurveyContainer {
    padding-top: 30px;
    margin-bottom: 30px !important;
    width: 500px;
  }

  .blueLineContainer {
    width: 500px;
  }
}

@media all and (max-width: 1250px) {
  .BAValuesMainDiv {
    background-color: #f6f6f6;
  }

  .BAWrapper {
    background-color: #f6f6f6;
  }
}

.reviewAnswers {
  box-sizing: border-box;
  width: 846px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  margin: 48px auto;
  padding: 60px 40px;
}
.reviewAnswers__title {
  margin: 0 0 32px;
}
.reviewAnswers__list {
  padding: 0;
}
.reviewAnswers__button {
  cursor: pointer;
  border-radius: 6px;
  border: none;
}
.reviewAnswers__button--submit {
  padding: 8px 44px;
  background-color: #343a54;
  color: #ffffff;
  margin: 0 0 1rem 0;
}

.resultsRow {
  list-style-type: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem 0;
}
.resultsRow--first {
  border-bottom: 1px solid #e0e1ee;
  padding: 0;
}
.resultsRow--odd {
  background-color: #fafafd;
}

.resultsRow__question {
  width: 40%;
  padding-left: 8px;
}

.resultsRow__answer {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resultsRow__answer--mobile {
  display: none;
}

.stepUp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
