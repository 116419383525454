.viewActions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-bottom: 40px;
}

@media all and (min-width: 1250px) {
  .viewActions > .btn-discard {
    margin-right: 20px;
  }
}

@media all and (max-width: 1250px) {
  .viewActions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 40px;
  }

  .viewActions button {
    flex: 1;
    border-radius: 0px;
  }
}
