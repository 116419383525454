.automaticThought-editInputText {
  height: 40px !important;
}

.automaticThought-list {
  list-style-type: none;
  padding: 0;
  margin-top: 0px;
}

.tj-Modal {
  height: unset;
}

.thinkingTrap {
  cursor: pointer;
  grid-column: 1fr;
  grid-row: auto;
  border: 2px solid #dbdbdb;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 10px 15px -10px rgba(204, 204, 204, 1);
  box-sizing: border-box;
}

.thinkingTrap * {
  cursor: pointer;
}

.thinkingTrap label {
  font-weight: 600;
  font-size: 14px;
}

.thinkingTrap p {
  font-size: 12px;
  margin-top: 7px;
}

@media screen and (max-width: 950px) {
  .thoughtJournalModal {
    font-size: 12px;
  }
}
