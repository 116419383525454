.offline-notification {
  transition: transform 0.3s;

  position: sticky;
  left: 50%;
  background: #222;
  color: white;
  padding: 0.5em;
  text-align: center;
}
.closeOfflineMessage {
  float: right;
  cursor: pointer;
}

.offline-notification a {
  color: white;
  text-decoration: underline;
}
.offline-notification a:visited {
  color: white;
}
.offline-notification:hover .offline-notification-explanation {
  display: block;
}
.offline-notification-explanation {
  display: none;
  background: inherit;
  padding-top: 1em;
}

.offline-msg {
  display: none;
}
.offline .offline-notification {
  transition: transform 0.3s;
  transform: translateY(0) translateX(-50%);
}
.offline .online-msg {
  display: none;
}
.offline .offline-msg {
  display: block;
}
