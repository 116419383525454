.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notificationContainer {
  position: fixed;
  box-sizing: border-box;
  width: 350px;
  max-width: 500px;
  bottom: 20px;
  right: 20px;
  z-index: 999999;
  color: #fff;
}

.horizontalLine {
  color: rgba(255, 255, 255, 0.37);
}

.notification {
  width: 100%;
  max-height: 250px;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 10px;
  border: 1px solid;
  border-radius: 7px;
  animation: fadeIn 1s 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.notificationCheckmark {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  padding: 1px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  margin: 0px 10px;
  justify-content: center;
  align-items: center;
}

.notificationFailure {
  width: 20px;
  cursor: pointer;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  padding: 1px;
  background-color: #fff;
  color: rgb(255, 118, 144);
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  margin: 0px 10px;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
}

.notification p {
  margin-right: 14px;
}

.noMarginTop {
  margin-top: 0px !important;
}

.marginLeft40 {
  margin-left: 40px;
}

.closeNotification {
  color: #fff;
  position: absolute;
  z-index: 10001;
  top: 5px;
  right: 5px;
  width: 30px;
  background-color: transparent;
  cursor: pointer;
  font-size: 33px !important;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10000;
  -webkit-transform: translate3d(0, 0, 0);
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  justify-content: center;
  align-items: center;
}

.fullHeight {
  height: 100%;
}

.fullHeightDesktop {
  height: 100%;
}

.modalContent {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  max-width: 780px;
  width: 780px;
  height: 90%;
  max-height: 650px;
  overflow: auto;
  box-sizing: border-box;
}

.modalContentPadding {
  margin: 0 auto;
  margin-bottom: 40px;
}

.modalContent p {
  margin: 1em 0;
}

.routesContainer {
  height: 100%;
}

.flexRow {
  flex-flow: row nowrap;
}

.flexColumn {
  flex-flow: column nowrap;
}

.flexJustifyCenterChildren {
  justify-content: center;
}

.flexAlignStartChildren {
  align-items: flex-start;
}

.flexAlignCenterChildren {
  align-items: center;
}

.flexJustifyStartChildren {
  justify-content: flex-start;
}

.flexJustifyEndChildren {
  justify-content: flex-end;
}

.fullWidth {
  width: 100%;
  box-sizing: border-box;
}

.flexContainer {
  display: flex;
}

.flexAlignCenter {
  align-self: center;
}

.flexAlignStart {
  align-self: flex-start;
}

.flexJustifyStart {
  justify-self: flex-start;
}

.flexCenter {
  align-self: center;
  justify-self: center;
}

.flexJustifyCenter {
  justify-self: center;
}

.radioOption {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.radioOptionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}

.radioOptionBtn > div[data-checked="false"] {
  background-color: #fff;
  border: 1px solid #a5a5a6;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.radioOptionBtn > div[data-checked="true"] {
  background-color: #2c5370;
  border: 1px solid #2c5370;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.radioOptionBtn > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
}

.radioOption label {
  font-size: 14px;
  font-weight: 400;
  color: #0b2336;
  user-select: none;
  margin-left: 5px;
}

.radioOptionGroupLabel {
  width: 30%;
}

.radioOptionGroupItems {
  width: 70%;
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 0px;
  align-items: center;
}

.radioOptionContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  max-width: 140px;
  margin-right: 30px;
}

.radioOptionBtn > div[data-checked="false"] {
  background-color: #fff;
  border: 2px solid #eaeff4;
  user-select: none;
}

.radioOptionBtn > div[data-checked="true"] {
  background-color: #2c5370;
  border: 2px solid #2c5370;
  color: #fff;
  user-select: none;
}

.radioOptionBtn > div:hover {
  cursor: pointer;
  background-color: #2c5370;
  border: 2px solid #2c5370;
  color: #fff;
}

.body {
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  align-items: center;
  overflow: auto;
}

.bodyContainer {
  width: 90%;
  overflow: visible;
  margin: 0 auto;
  height: 100%;
}

.sectionContainer {
  overflow: auto;
  flex: 1;
  width: 100%;
}

.dasboardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dasboardContainer section {
  display: contents;
}

.darkTheme section {
  max-width: 1200px;
  margin: 0 auto;
}

.overflowAuto {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.admin-dashboard {
  padding-top: 66px;
}

.bodyContainerChild {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerMargin {
  margin: 0 auto;
}

.noMarginTop {
  margin-top: 0px;
}

.formContainer label {
  font-size: 14px;
}

.label {
  font-size: 14px;
  font-weight: 700;
}

.blueBorder {
  border-color: #3f99f7 !important;
  border-width: 2px !important;
}

.thickBlueBorder {
  border-color: #3f99f7 !important;
  border-width: 3px !important;
  margin-top: 100px;
}

.draftJSHtmlContentWritten {
  line-height: 1.58em;
  letter-spacing: -0.003em;
}

.draftJSHtmlContentWritten h1,
.demo-editor h1 {
  font-weight: 600;
  font-size: 27px !important;
  color: #000 !important;
  line-height: 1.6em;
}

.draftJSHtmlContentWritten h2,
.demo-editor h2 {
  font-weight: 400;
  font-size: 20px;
  color: #000 !important;
}

.draftJSHtmlContentWritten h3,
.demo-editor h3 {
  font-weight: 400;
  font-size: 19px;
  color: #3b9af7 !important;
}

.draftJSHtmlContentWritten h4,
.demo-editor h4 {
  font-weight: 400;
  font-weight: normal;
  font-size: 19px;
  color: #000;
}

.draftJSHtmlContentWritten p,
.demo-editor p {
  font-weight: 500;
  font-size: 16px;
  color: #000 !important;
  margin: 20px 0px;
}

.draftJSHtmlContentWritten ul,
.demo-editor ul {
  color: #000;
  padding-left: 50px;
}

.draftJSHtmlContentWritten > hr,
.demo-editor hr {
  width: 100%;
  margin-left: 0;
  border: 1px solid var(--core-color-slate-10);
  margin-top: 50px;
  margin-bottom: 50px;
}

.hr-blue {
  width: 76px;
  margin-left: 0;
  border: 2px solid #3f99f7;
  margin-top: 50px;
  margin-bottom: 50px;
}

.draftJSHtmlContentWritten > img,
.demo-editor img {
  display: block;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100% !important;
  max-width: 750px;
  box-sizing: border-box;
}

.sessionSpacing > div > hr {
  width: 76px;
  margin-left: 0;
  border: 2px solid #3f99f7;
  margin-top: 50px;
  margin-bottom: 50px;
}

.fullScreen {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(255, 255, 255); /* Fallback color */
  background-color: rgba(255, 255, 255, 1); /* Black w/ opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreen > #loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.miniLoaderContainer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.miniLoader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.pullRight {
  display: flex;
  justify-content: flex-end;
  flex-flow: row nowrap;
}

.center {
  max-width: 933px;
  margin: 0 auto;
}

.centerFlex {
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  overflow: visible;
}

.centerFlexInline {
  position: relative;
  display: inline-flex;
  justify-content: center;
  flex-flow: row nowrap;
}

.centerFlexColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.sidebar {
  height: 100%;
  background-color: #f6f6f6;
}

.sidebarContent {
  position: relative;
  width: 100%;
  overflow-y: auto;
  background-color: #f6f6f6;
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* The Modal (background) */
#personaModal > .modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
#personaModal > .modal > .modalContent {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: auto;
  max-width: 600px;
  height: auto;
  display: inline-block;
  flex-flow: column nowrap;
  margin: auto;
}

.close {
  position: absolute;
  z-index: 10001;
  top: 0px;
  background-color: transparent;
  right: 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  cursor: pointer;
  width: 55px;
  height: 55px;
  font-size: 33px !important;
}

.close span {
  display: block;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 10px;
  padding: 0px 15px;
  color: #d5d5d5;
}

.close > span:hover {
  background-color: #3f99f7;
  color: #fff;
}

.closeTip {
  position: absolute;
  z-index: 10001;
  top: 5px;
  margin: 0px;
  background-color: transparent;
  right: 5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  cursor: pointer;
  width: 55px;
  height: 55px;
  font-size: 33px !important;
}

.closeTip span {
  border-radius: 50%;
  color: #d5d5d5;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeTip > span:hover {
  background-color: #3f99f7;
  color: #fff;
}

/* The Modal (background) */
#sessionCompleteModal > .modal {
  display: none; /* Hidden by default */
  position: fixed !important; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
#sessionCompleteModal > .modal > .modalContent {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 566px;
  height: 460px;
  opacity: 1;
  display: flex;
  flex-flow: column nowrap;
  margin: auto;
  transition: all 0.15s linear;
  animation:
    animateSessionCompleteModal 0.5s,
    opacity 1s;
  border-radius: 10px;
}

@keyframes animateSessionCompleteModal {
  from {
    height: 50px;
    width: 20px;
  }
  to {
    width: 566px;
    height: 460px;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#sessionCompleteModal > .modal > .modalContent > .modalContentFlex {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.modalQuickTip {
  background-color: #edf2f2;
  padding: 20px;
  border-radius: 7px;
  font-size: 12px;
}

.modalQuickTip header {
  font-size: 14px;
  font-weight: 600;
}

.modalQuickTip p {
  font-size: 12px;
}

#viewTipsModal {
  position: relative;
  z-index: 9999;
}

/* Modal Content */
#viewTipsModal > .modal > .modalContent {
  width: 50%;
  height: auto;
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  display: inline-block;
  flex-flow: column nowrap;
  margin: auto;
}

#viewTipsModal > .modal > .modalContent > .close {
  cursor: pointer;
  width: auto;
  font-size: 33px;
  margin-top: 0;
  text-align: right;
  color: #959595;
}

.joinNowButton {
  position: absolute;
  top: 4%;
  right: 5%;
  color: #3f99f7;
  font-size: 15px;
  text-decoration: none;
}

.loginLink {
  color: #a3cefb;
  text-decoration: none;
}

.pdfBtnLoading {
  background-color: #73b6fb;
  pointer-events: none;
}

.fieldset {
  width: 80%;
  box-sizing: border-box;
}

.changePasswordError {
  border: 1px solid red;
  background-color: #f003;
  color: red;
  padding: 10px;
  margin: 20px;
}

.fabFixedWrapper {
  padding-left: 10px;
  padding-right: 10px;
  min-width: 133px;
  height: 42px;
}

.fab {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  outline: none;
  position: relative;
  width: auto;
  margin: 20px auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 186px;
  height: 42px;
  background-color: #1fd077;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: box-shadow 0.1s ease-in;
}

.fab:hover {
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #1fb268;
}

.fab:focus-visible {
  border: 1px solid red;
}

.writtenViewOrderedItem {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #000;
}

.orderedNumber {
  font-weight: 700;
  font-size: 26px;
  align-self: flex-start;
  margin-top: 3px;
  white-space: nowrap;
}

.orderedText {
  font-weight: 400;
  font-size: 16px;
  margin-left: 20px;
}

.flatpickr-container input {
  color: #424242;
  background-color: #f6f6f6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 14px;
  padding: 10px 10px 5px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #607d8b;
  z-index: 10001;
}

.flatpickr-container input:focus {
  outline: none;
  border-bottom: none !important;
}

.bar {
  position: relative;
  display: block;
  width: 315px;
}

.bar:before,
.bar:after {
  background: #0288d1;
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

.flatpickr-container input:focus ~ .bar:before,
.flatpickr-container input:focus ~ .bar:after {
  width: 50%;
}

.userListSpinnerContainer img {
  margin: 0px auto !important;
  width: 100px !important;
}

.form {
  margin: 50px auto;
  max-width: 750px;
}

.sessionHr {
  width: 76px;
  margin-left: 0;
  border: 2px solid #3f99f7;
  margin-top: 50px;
  margin-bottom: 50px;
}

.indexView {
  max-width: 680px;
  margin: 0 auto;
}

.toolContainer {
  max-width: 796px;
  margin: auto;
  padding: 56px 24px 0px 24px;
}

.sidebarContainer {
  margin: 0 0 0 auto;
  padding: 56px 40px 0px 40px;
  background: #f5fafa;
  height: calc(100vh - 66px);
}

.ToolActions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.correctCheck {
  min-height: 30px;
  min-width: 30px;
}

.noMatch {
  margin-left: 20px;
}

@media screen and (max-width: 1250px) {
  .radioOptionGroup {
    justify-content: space-evenly;
  }

  .radioOptionContainer {
    min-width: 45px;
    max-width: 50px;
  }

  .fullHeight {
    height: 100vh;
  }

  .ToolActions > * {
    width: 100%;
  }

  .indexView {
    max-width: 90%;
  }

  .toolContainer {
    width: 100%;
  }

  .toolContainer .formContainer {
    width: 100%;
    max-width: none;
  }

  .sidebarContent {
    background-color: white;
    z-index: 5;
    background-color: #f6f6f6;
    top: 0px;
    bottom: 0px;
    left: 0px;
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }

  .form {
    padding: 0px 15px;
    margin: 0 auto;
  }

  .radioOptionBtn > div {
    width: 60px !important;
  }
}
