.chatSidebar a {
  color: #3f99f7;
}

.chatSidebar > p,
.chatSidebar > a {
  padding: 20px;
  padding-bottom: 0px;
  text-decoration: none;
}

.chatMessageInputContainer {
  align-items: center;
  width: 100% !important;
  margin: 0 !important;
  display: flex;
  background-color: white;
  flex-flow: row nowrap;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: 15px;
}

@media screen and (max-width: 1250px) {
  .chatMessageInputContainer {
    position: fixed;
    bottom: 0;
  }
}

.chatMessageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  width: 100%;
  padding: 15px 20px;
}

.chat-options {
  position: absolute;
  right: 0;
  top: -12px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid lightgrey;
  min-width: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-right: 10px;
  padding: 2px;
  cursor: pointer;
}

.chat-options-first-message {
  top: 5px;
}

.chat-option-item:hover {
  background-color: #ecebeb;
}

.chat-option-item {
  padding: 5px;
  cursor: pointer;
}

.chatMetadata {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 20px;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  font-size: 14px;
}

.errorText {
  font-size: 40px;
  color: red;
}

.chatMobileHeader span {
  margin-right: 20px;
}

.chatConnecting {
  margin: 0 auto;
}

.chatConnecting img {
  max-width: 100px;
}

.noPractitionerBorderDiv {
  width: 80%;
  position: absolute;
  margin-left: 8%;
  margin-right: 10%;
  bottom: 70px;
  border: 2px solid #5896c9;
  border-radius: 8px;
  background-color: #fff;
}

.noPractitionerPaddingDiv {
  padding: 3%;
  display: flex;
  justify-content: space-around;
}

.noPractitionerHeader {
  font-size: 16px;
  color: #033e5f;
  font-weight: 600;
}

.noPractitionerSubHeader {
  font-size: 15px;
  color: #033e5f;
  margin-top: 2%;
}

@media all and (max-width: 1250px) {
  .noPractitionerBorderDiv {
    width: 89%;
    position: sticky;
    margin-left: 5%;
    margin-right: 10%;
    margin-top: -80%;
    border: 2px solid #5896c9;
    background: white;
    border-radius: 8px;
  }

  .noPractitionerPaddingDiv {
    padding: 3%;
    display: flex;
    justify-content: space-around;
    flex-flow: column;
  }
}
