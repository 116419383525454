.sessionPartViewContainer {
  height: 100%;
  width: 100%;
}

.fade {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5), rgb(255, 255, 255, 1));
}

.accessibilityError {
  color: #3f99f7;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.extraInformationContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.sessionP {
  font-weight: 400;
  font-size: 16px;
  color: #3b9af7;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: normal;
}

.sessionErrorMessage {
  text-align: center;
  margin-top: 40px;
}

.sessionSpacer {
  height: 60px;
}

.sessionToolSection .sessionTitle {
  display: none;
}

.sessionToolSection .sessionSpacer {
  display: none;
}

.sessionToolSection .indexView {
  width: 90% !important;
}

.sessionStepSidebar {
  width: 70px;
  padding: 30px;
  border-right: 1px solid lightgrey;
  list-style-type: none;
  margin: 0px;
  color: grey;
  overflow: auto;
}

.sessionStepSidebar li {
  cursor: pointer;
}

.sessionStepSidebar .active {
  color: #3f99f7;
  font-weight: 700;
}

.sessionStepSidebar li:not(:first-child) {
  margin-top: 30px;
}

.sessionContainer {
  display: flex;
  height: 100%;
}

.sessionSection {
  max-width: 1200px;
  margin: 0 auto;
}

.sessionPartTitle {
  font-size: 25px;
  margin-top: 0px;
}

.sessionPartViewContainer {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.incorrectRadioOptionAnswer {
  font-weight: 600;
  font-size: 16px;
}

.incorrectRadioOptionAnswer > span {
  font-weight: 400;
  font-size: 16px;
}

.radioButtonAnswer {
  width: 100%;
}

.radioButtonAnswer > div {
  display: flex;
  align-items: center;
}

.outerFlexTable {
  display: flex;
  flex-flow: row nowrap;
}

.innerFlexTable {
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid lightgrey;
  flex: 50%;
}

.innerFlexTableHeader {
  border: 0px solid black;
  font-weight: 500;
  font-size: 15px;
  color: #000;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid lightgrey;
  text-align: center;
  min-width: 80px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.innerFlexTableHeader > p {
  margin: 0;
}

.innerFlexTableAnswers > div:nth-child(even) {
  background-color: lightgrey;
}

.innerFlexTableAnswers > div {
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerFlexTableAnswerInput {
  width: 80%;
}

p.deleteTableAnswer {
  cursor: pointer;
}

@keyframes border-pulsate {
  0% {
    color: rgba(63, 153, 247, 1);
    border-color: rgba(63, 153, 247, 1);
  }
  50% {
    color: rgba(63, 153, 247, 0.1);
    border-color: rgba(63, 153, 247, 0.1);
  }
  100% {
    color: rgba(63, 153, 247, 1);
    border-color: rgba(63, 153, 247, 1);
  }
}

.sessionQuizTitle {
  font-weight: 700;
  font-size: 18px;
}

.radioBtnContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
}

.radioBtnContainer * {
  cursor: pointer;
}

.quizRadioOptionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeBar {
  width: 20px;
  min-width: 20px;
  height: 100%;
  position: absolute;
  border: 3px solid #fff;
  border-top: 0px;
  border-bottom: 0px;
  border-left: 0px;
}

.sidebarSessionContainer {
  margin-left: 30px;
  position: relative;
  padding: 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.activeComment .sidebarSessionPartTitleContainer,
.activeComment .sidebarSessionPartDescription {
  color: #959595;
}

.sidebarSessionPartTitle {
  font-size: 15px;
  margin-right: 10px;
  color: #000;
  font-weight: 600;
}

.sidebarSessionPartDescription {
  padding: 0px;
  font-size: 14px;
}

.sidebarSessionEstCompletionTime {
  font-size: 14px;
}

.p {
  margin-bottom: 0px;
  line-height: 1.5;
  position: relative;
  border-radius: 4px;
}

.p:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #b9b9b9;
  background: #fff;
  position: absolute;
  left: -19px;
}

.sessionAccordion {
  width: 375px;
  height: 100%;
  overflow: auto;
  border-right: 1px solid #95959580;
  overflow: visible;
  overflow-y: auto;
  z-index: 1001; /* Needs to be above the header */
  display: flex;
  flex-flow: column nowrap;
}

.accordionSection {
  border-bottom: 1px solid white;
  color: #fff;
}

.accordionCompletionTime {
  margin-right: 20px;
}

.accordionCompletionTime > span {
  opacity: 0.6;
}

.completedSessionCheckmark {
  width: auto;
  height: auto;
  border: 1px solid lightgrey;
  border-radius: 50%;
  padding: 20px;
}

.sessionMobileHeader {
  justify-content: space-between;
}

.sessionOpenAccordion {
  margin-right: 15px;
  margin-left: 45px !important;
  font-weight: 400;
  white-space: nowrap;
}

.mobileSessionSidebar .accordionSectionHeader {
  white-space: nowrap;
}

.session-tip-modal {
  height: auto;
}

.intro-video {
  width: 100%;
  height: auto;
}

.saving-load {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0px 5px;
  background-color: #3f99f7;
  color: white;
  border-top-left-radius: 5px;
  -webkit-animation: fadein 0.4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.4s; /* Firefox < 16 */
  -ms-animation: fadein 0.4s; /* Internet Explorer */
  -o-animation: fadein 0.4s; /* Opera < 12.1 */
  animation: fadein 0.4s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/************DROP DOWN MENU SESSIONS CONTAINER*************/

@media screen and (max-width: 1250px) {
  .fade {
    height: calc(100% - 100px);
  }

  .innerFlexTableHeader {
    font-size: 12px;
    min-height: 70px;
  }

  .sessionAccordion {
    width: 100%;
  }

  .sessionSpacing .submitBtn-medium {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .innerFlexTableHeader {
    height: 45px;
    font-size: 10px;
  }

  .sessionAccordion {
    width: 345px;
    border: none;
  }

  .accessibilityError {
    color: #3f99f7;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 90%;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
