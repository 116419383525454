.sliderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.slider-error-message {
  color: #ff4e4e;
  font-weight: 600;
  margin: 3px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  padding: 10px 0px;
  border-radius: 5px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  cursor: pointer;
}

.slider:focus-visible {
  border: 2px solid #0a5eb5;
}

.slider::-webkit-slider-runnable-track {
  height: 8px;
  border-radius: 2px;
}

.slider::-moz-range-track {
  height: 8px;
  border-radius: 2px;
}

.slider-color::-moz-range-track {
  background: transparent linear-gradient(270deg, #3f99f7 0%, #1fd077 25%, #ebd106 67%, #ff0000 100%) 0% 0% no-repeat
    padding-box;
}
.slider-color::-webkit-slider-runnable-track {
  background: transparent linear-gradient(270deg, #3f99f7 0%, #1fd077 25%, #ebd106 67%, #ff0000 100%) 0% 0% no-repeat
    padding-box;
}

.slider-color-reverse::-moz-range-track {
  background: transparent linear-gradient(270deg, #ff0000 0%, #ebd106 25%, #1fd077 67%, #3f99f7 100%) 0% 0% no-repeat
    padding-box;
}
.slider-color-reverse::-webkit-slider-runnable-track {
  background: transparent linear-gradient(270deg, #ff0000 0%, #ebd106 25%, #1fd077 67%, #3f99f7 100%) 0% 0% no-repeat
    padding-box;
}

/* ----DEPRESSION----*/
.slider-depression-reverse-color::-moz-range-track {
  background: transparent linear-gradient(90deg, #1fd077 0%, #3f99f7 100%) 0% 0% no-repeat padding-box;
}
.slider-depression-reverse-color::-webkit-slider-runnable-track {
  background: transparent linear-gradient(90deg, #1fd077 0%, #3f99f7 100%) 0% 0% no-repeat padding-box;
}
.slider-depression-color::-moz-range-track {
  background: transparent linear-gradient(90deg, #3f99f7 0%, #1fd077 100%) 0% 0% no-repeat padding-box;
}
.slider-depression-color::-webkit-slider-runnable-track {
  background: transparent linear-gradient(90deg, #3f99f7 0%, #1fd077 100%) 0% 0% no-repeat padding-box;
}
/*--------*/

.slider::-webkit-slider-thumb {
  position: relative;
  border: 2px solid #3f99f7;
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  top: -8px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  outline: none;
}

.slider::-moz-range-thumb {
  border: 2px solid #3f99f7;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  outline: none;
}

.slider::-moz-focus-outer {
  border: 0;
}

.slider::-webkit-focus-outer {
  border: 0;
}

.slider-inactive::-webkit-slider-thumb {
  position: relative;
  left: 5%;
}
.slider-inactive-color::-webkit-slider-runnable-track {
  background: transparent
    linear-gradient(
      270deg,
      rgba(63, 153, 247, 0.3) 0%,
      rgba(31, 208, 119, 0.3) 25%,
      rgba(235, 209, 6, 0.3) 67%,
      rgba(255, 0, 0, 0.3) 100%
    )
    0% 0% no-repeat padding-box;
}

.slider-inactive-color::-moz-range-track {
  background: transparent
    linear-gradient(
      270deg,
      rgba(63, 153, 247, 0.3) 0%,
      rgba(31, 208, 119, 0.3) 25%,
      rgba(235, 209, 6, 0.3) 67%,
      rgba(255, 0, 0, 0.3) 100%
    )
    0% 0% no-repeat padding-box;
}

.slider-inactive-color-reverse::-webkit-slider-runnable-track {
  background: transparent
    linear-gradient(
      270deg,
      rgba(255, 0, 0, 0.3) 0%,
      rgba(235, 209, 6, 0.3) 25%,
      rgba(31, 208, 119, 0.3) 67%,
      rgba(63, 153, 247, 0.3) 100%
    )
    0% 0% no-repeat padding-box;
}

.slider-inactive-color-reverse::-moz-range-track {
  background: transparent
    linear-gradient(
      270deg,
      rgba(255, 0, 0, 0.3) 0%,
      rgba(235, 209, 6, 0.3) 25%,
      rgba(31, 208, 119, 0.3) 67%,
      rgba(63, 153, 247, 0.3) 100%
    )
    0% 0% no-repeat padding-box;
}

/* ----DEPRESSION----*/
.slider-depression-reverse-inactive-color::-moz-range-track {
  background: transparent linear-gradient(90deg, rgba(31, 208, 119, 0.3) 0%, rgba(121, 147, 175, 0.3) 100%) 0% 0%
    no-repeat padding-box;
}
.slider-depression-reverse-inactive-color::-webkit-slider-runnable-track {
  background: transparent linear-gradient(90deg, rgba(31, 208, 119, 0.3) 0%, rgba(121, 147, 175, 0.3) 100%) 0% 0%
    no-repeat padding-box;
}
.slider-depression-inactive-color::-moz-range-track {
  background: transparent linear-gradient(90deg, rgba(121, 147, 175, 0.3) 0%, rgba(31, 208, 119, 0.3) 100%) 0% 0%
    no-repeat padding-box;
}
.slider-depression-inactive-color::-webkit-slider-runnable-track {
  background: transparent linear-gradient(90deg, rgba(121, 147, 175, 0.3) 0%, rgba(31, 208, 119, 0.3) 100%) 0% 0%
    no-repeat padding-box;
}
/*--------*/

.slider-value {
  margin: 0;
  font-weight: 800;
  font-size: 22px;
}

.slider-value-empty {
  margin: 0;
  font-size: 15px;
  color: black;
  font-weight: 400;
}

.slider-min-max {
  position: absolute;
  margin: 0;
  font-weight: 800;
  font-size: 15px;
  color: black;
}

.slider-answer-container {
  position: relative;
  min-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .slider {
    margin-left: 0px;
  }
}
