.updateCardContainer {
  width: 560px;
}

.updateCardContainer > p {
  color: #173e5c;
  text-align: center;
}

.updateCardHeader {
  font-weight: 400;
  color: #173e5c;
  text-align: center;
}

.cardInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}

.updateCardLabels {
  font-weight: 600;
  color: #173e5c;
}

.cardNumberContainer > div {
  margin: 0;
  margin-top: 5px;
}

.updateCardBtnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.updateCardBtnContainer > button {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@media screen and (max-width: 700px) {
  .updateCardContainer {
    width: 310px;
  }

  .cardInfoContainer {
    flex-direction: column;
    height: 250px;
    margin-left: 20px;
  }
}
