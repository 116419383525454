.subSchedule-form {
  display: flex;
  flex-flow: row;
}

.subSchedule-plan {
  padding-top: 50px;
}

.subSchedule-crud hr {
  margin: 60px 0px;
  width: 100%;
}

.subSchedule-plan .phase {
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.subSchedule-plan .phase .close {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 5px;
  height: 20px;
  width: 20px;
  margin: 0px;
  font-size: 20px !important;
  border-radius: 50%;
}

.subSchedule-plan .phase .close:hover {
  background-color: #3f99f7;
}

.subSchedule-plan .phase .close span {
  color: #d5d5d5 !important;
  margin: 0px;
  padding: 0px;
}

.subSchedule-plan .phase .close span:hover {
  background-color: rgba(0, 0, 0, 0);
}

.subSchedule-plan .phase-col {
  display: flex;
  flex-flow: column nowrap;
}

.subSchedule-plan .phase-col p {
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 21px;
}

.subSchedule-plan .phase-col .itemTitle {
  font-weight: 700;
  color: rgb(23, 62, 92);
  font-size: 14px;
  margin-right: 15px;
}

.subSchedule-plan h1 {
  color: #173e5c;
  font-weight: 400;
  font-size: 24px;
}

.subSchedule-plan-container {
  min-width: 350px;
  margin: 10px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
}

.subSchedule-plan-container ul {
  list-style: none;
  padding: 0px;
}

.subSchedule-coupon-table {
  box-shadow: 0px 10px 15px 2px #e6e6e6;
  border-spacing: 0;
}

.subSchedule-coupon-table tr {
  height: 40px;
}

.subSchedule-coupon-table tr:hover {
  background-color: #f2f2f2 !important;
}

.subSchedule-coupon-table th {
  background-color: #f2f5f7;
  border-collapse: collapse;
  color: #566677;
  width: 180px;
}
