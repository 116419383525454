.checkoutForm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.checkoutForm input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 0px;
  background-color: #f6f6f6;
  width: 88%;
  box-sizing: border-box;
  margin-left: 5%;
}

.applyDiscount-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.applyDiscount-container input {
  width: 40%;
}

.applyDiscount-container .applyCoupon {
  font-weight: 600;
  text-transform: none !important;
  width: 150px !important;
  font-size: 1.1em !important;
  border-radius: 0;
  height: 50px;
  color: #fff;
  background-color: #3b9af7 !important;
  margin: 10px 0 20px 0;
  margin-left: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkoutBreakdown {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  margin-right: 20px;
  padding-top: 10px;
  width: 23%;
  margin-right: 3%;
  background-color: #fff;
}

.checkoutBreakdown h5 {
  margin-left: 24px;
  margin-right: 24px;
}

.checkoutForm label {
  color: #173e5c;
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 20px;
}

@media screen and (min-width: 1400px) {
  .checkoutForm label {
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .checkoutForm label {
    margin-right: 0;
  }
}

.checkoutForm input::placeholder {
  color: #aab7c4;
}

.checkoutForm input:focus,
.StripeElement--focus {
  box-shadow:
    rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.PaymentRequestButton {
  padding: 0;
}

.paymentDiv {
  background-color: #fff;
  padding: 1%;
  width: 17%;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  padding-top: 3%;
}

.checkoutForm button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border: 0;
  outline: 0;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.025em;
  background-color: #173e5c;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  height: 50px;
  width: 60%;
  margin-left: 5%;
}

.checkoutForm button:active {
  color: #fff;
  cursor: pointer;
  background-color: #64acf8;
  box-shadow:
    0 7px 14px rgba(50, 50, 93, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.08);
}

.checkoutPromoSubDiv {
  background-color: #1fd077;
  color: white;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  margin-right: 7%;
  width: fit-content;
  margin-left: 20px;
  margin-top: 9px;
  margin-bottom: 20px;
}

.promoCodeChange:hover {
  cursor: pointer;
}

@media not all and (hover: none) {
  .checkoutForm button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #64acf8;
    box-shadow:
      0 7px 14px rgba(50, 50, 93, 0.1),
      0 3px 6px rgba(0, 0, 0, 0.08);
  }
}

.checkoutForm .errorBox {
  margin: 0 auto;
  margin-bottom: 20px;
}

.checkoutSpinner {
  width: 40px;
}

@media all and (max-width: 1250px) {
  .paymentHeader {
    display: flex;
    flex-flow: column-reverse;
    align-items: center;
    justify-content: space-between;
  }

  .paymentHeader > h1 {
    margin-top: 0;
  }

  .checkoutForm {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  .checkoutForm button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    border: 0;
    outline: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.025em;
    background-color: #173e5c;
    text-decoration: none;
    transition: all 150ms ease;
    height: 50px;
    width: 60%;
    margin-left: 18%;
    margin-bottom: 3%;
  }

  .applyDiscount-container {
    display: flex;
    align-items: center;
  }

  .applyDiscount-container input {
    width: 100%;
  }

  .applyDiscount-container .applyCoupon {
    font-weight: 600;
    text-transform: none !important;
    width: auto !important;
    height: 30px !important;
    font-size: 1em !important;
    padding: 5px;
    margin: 0px;
  }
}
