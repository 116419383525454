.bv-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px 0px;
}

.bv-line-value {
  display: flex;
  align-items: center;
  margin: 7px 0px;
}

.bv-answer-indicator {
  margin-right: 10px;
}

.bv-line {
  height: 16px;
}

.bv-value {
  margin-left: 10px;
}

.bold-text {
  font-weight: 600;
}

.medium-text {
  font-weight: 400;
}

.diagSlider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  outline: none;
  opacity: 1;
  cursor: auto;
}

.diagSlider::-webkit-slider-runnable-track {
  height: 20px;
}

.diagSlider::-moz-range-track {
  height: 20px;
}

.diagSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 5px;
  height: 40px;
  border-radius: 0px;
  border: none;
  background: black;
  cursor: auto;
  margin-top: -12px;
}

.diagSlider::-moz-range-thumb {
  width: 5px;
  height: 50px;
  border-radius: 0px;
  border: none;
  background: black;
  cursor: auto;
}

.diagSliderLabels {
  position: relative;
  min-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.diagSliderLabels > span {
  font-weight: 600;
}

.diagSliderTopValue {
  font-size: 30px;
  font-weight: 600;
}
