.sessionIndexContainer {
  display: flex;
  height: 100%;
  overflow: auto;
}

.builder-container {
  padding: 20px;
}

.session-builder-index {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.builder-input {
  margin-top: 10px;
}

.sidebar-top {
  display: flex;
}

.darkTheme {
  background-color: #1c1f2b;
  color: #fff;
  font-size: 16px;
}

.darkTheme h1 {
  color: #fff;
}

.darkTheme > div {
  width: 100%;
}

.darkTheme .sidebar {
  background-color: #2d3143;
  color: #bcc1d2;
  border: none;
}

.darkTheme .sidebar h5 {
  color: #bcc1d2;
}

.darkTheme .sidebarHeader {
  height: auto;
  font-size: 1em;
}

.darkTheme .submitBtnTransparent-auto {
  border: 1px solid #fff;
  color: #fff;
  transition: all 0.2s;
}

.darkTheme .submitBtnTransparent-auto:hover {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #3f99f7;
  color: #3f99f7;
  transition: background-color 0.2s;
  cursor: pointer;
}

.darkTheme label {
  color: #bdcadb !important;
}

.darkTheme .textFloat {
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid #3f99f7;
  color: #fff;
  font-size: 0.8em;
}

.darkTheme .textArea {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #3f99f7;
  color: #fff;
  font-size: 0.8em;
}

.darkTheme .sidebarItemsContainer {
  font-size: 1em;
  font-weight: 700;
  padding-left: 20px;
  list-style-type: none;
  margin: 0;
}

.darkTheme .activeNavItem {
  background-color: #1c1f2b;
  border: 0px !important;
  color: #fff;
}

.darkTheme .navItem {
  cursor: pointer;
  padding: 10px 15px;
  border: 0px;
}

.darkTheme .navItem:hover {
  background-color: #1c1f2b;
  color: #fff;
}

.demo-wrapper {
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 10px;
  color: #000;
}

.demo-editor {
  padding: 10px;
  border: 1px solid grey;
  max-height: 200px;
  overflow-y: scroll;
}

.partItem:hover {
  background-color: #f5f5f5;
}

.partItem input[type="text"] {
  background-color: #fff;
  border: 0px;
  color: #000;
  padding: 5px;
  text-align: center;
}

.questionRadioOuterContainer {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: center;
}

.radioTitle {
  border: 1px solid #cccccc;
  background-color: #f6f6f6;
  height: 28px;
  width: 286px;
  color: #000000;
  font-size: 16px;
  padding: 8px;
  margin-top: 20px;
  font-family: San Francisco;
  margin: 10px;
  margin-left: 0;
}

.createContentOption {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #3f99f7;
  text-align: center;
  transition: background-color 0.2s;
  cursor: pointer;
  width: 50%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.createContentOption:hover {
  background-color: #cccccc;
}

.questionRadioInnerContainer {
  display: flex;
  justify-content: flex-start;
  flex-flow: row nowrap;
  align-items: center;
}

.sessionList {
  padding: 10px;
  border-collapse: collapse;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 0px 5px 10px 2px #07070966;
  background-color: #3a3e52;
  width: 100%;
}

.sessionList th {
  letter-spacing: 1.1px;
  color: #bcc1d2;
  font-weight: 700 !important;
  font-size: 13px;
  background-color: #2d3143;
}

.sessionList th,
.sessionList td {
  padding: 15px;
  padding-right: 40px;
  border: none;
  text-align: left;
  font-weight: 400;
}

.sessionList tr {
  cursor: pointer;
}

.sessionList tr:hover {
  background-color: #1c1f2b !important;
}

.sessionList tr:nth-child(even) {
  background-color: #2d3143;
}

.sessionList th {
  cursor: context-menu;
}

.subscriptionType {
  display: block;
  background-color: #d7f5ea;
  border-radius: 10px;
  padding: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin: 0 auto;
  width: 50%;
  text-align: center;
  font-size: 12px;
}

.sessionList #actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.sessionList #actions > * {
  padding: 5px;
}

@media only screen and (max-width: 1250px) {
  .sessionList {
    margin: 2px;
    padding: 2px;
    font-size: 11px;
  }

  .sessionList th {
    background-color: #4caf50;
    color: white;
  }

  .sessionList th,
  .sessionList td {
    padding: 5px;
    text-align: left;
    border: 1px solid #ddd;
  }

  .sessionList #actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  .sessionList #actions > * {
    padding: 2px;
  }
}
