.userViewContainer {
  max-width: 500px;
  margin: 0 auto;
}

.userView-action {
  border: 1px solid grey;
  box-sizing: border-box;
  padding: 15px;
  margin: 10px;
}

.userView-action select {
  margin-left: 10px;
}

.userSurveysContainer {
  padding: 10px;
}

.userSurveysContainer > div > div > div > div > b {
  color: #0d81fb;
}
