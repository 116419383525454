html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Gilroy", sans-serif;
  font-weight: 400;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
}

#root {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.modalOpen {
  overflow: hidden;
}

.modalOpen .desktop-content-container {
  overflow: hidden;
}

input[type="radio"] {
  margin-right: 10px;
  vertical-align: top;
}

button,
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

p {
  font-size: 15px;
}

b {
  font-weight: 700;
}

h1 {
  font-weight: 700;
  font-size: 22px;
  color: #000;
  margin: 0.67em 0;
}

.momentumScroll {
  -webkit-overflow-scrolling: touch;
}

.container {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  width: 100%;
}

.formActions {
  padding: 30px;
  padding-left: 0px;
  padding-right: 0px;
}

.headerCenter {
  margin: 0 auto !important;
  margin-top: 50px !important;
  width: 80% !important;
}

.headerCenter p {
  margin: 10px 0px;
}

.headerCenter h1 {
  font-weight: 400;
  margin: 0;
  margin-left: 10px;
}

.footerCenter {
  margin: 0 auto !important;
  margin-top: 50px !important;
  width: 80% !important;
}

.sideBarItem {
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid #cbcbcb;
}

.sideBarItem:first-child {
  border-top: 1px solid #cbcbcb;
}

.sideBarItem-disabled {
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid #cbcbcb;
}

.sideBarItem-disabled * {
  cursor: not-allowed !important;
}

.sideBarItem * {
  cursor: pointer !important;
}

.sideBarItem-active {
  background-color: #fff;
}

.sideBarItemContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  word-break: break-word;
  max-width: calc(100% - 30px);
}

.sideBarItemContentActive {
  border-left: 10px solid #3f99f7;
  color: #000;
  background-color: #fff;
}

.sideBarItemContentInactive {
  border-left: 10px solid #f6f6f6;
  color: #000;
}

.sideBarContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

input[type="number"],
input[type="password"],
input[type="tel"] {
  font-size: 16px;
  outline: none;
}

.errorMessages {
  color: #ff7690;
  font-size: 14px;
  border-radius: 7px;
  width: max-content;
  font-weight: 400;
  white-space: pre-line;
}

.label .errorMessages {
  max-width: 100%;
}

.signupForm .errorMessages {
  width: 100%;
  box-sizing: border-box;
}

.passwordChange .errorMessages {
  width: fit-content;
}

.notAllowedCursor * {
  cursor: not-allowed !important;
}

.mobileHeader {
  position: fixed;
  top: 0px;
  z-index: 1000;
  height: 50px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #173e5c;
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  width: 100% !important;
  max-width: none !important;
  margin-top: 0px !important;
}

.mobileHeader > span {
  margin-left: 15px;
}

.viewTips {
  display: inline-flex;
  font-size: 15px;
  color: #09709c;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.detailsViewNumberContainer {
  font-weight: 700;
}

.detailsViewNumber {
  font-size: 48px;
  font-weight: 500;
}

.detailsViewFooter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding-bottom: 40px;
}

.viewHeader {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 5px;
}

.viewTitle {
  font-size: 22px;
  font-weight: 500;
}

.genericValidatorNoMargin > div > div {
  padding: 0 !important;
}

.modalInner {
  width: 900px;
  height: 500px;
  min-height: 500px;
  background-color: #fff;
  border: 3px solid #3b9af7;
  border-radius: 30px;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-content: space-around;
  margin: auto;
}

.absoluteTopRight {
  position: absolute;
  right: 15px;
  top: 10px;
}

.planSituation {
  padding-left: 12px;
}

@media all and (max-width: 1250px) {
  .container {
    flex-flow: column nowrap;
  }

  .toolStep {
    padding: 0px;
  }

  .details > footer {
    width: 100%;
    margin: 0 auto;
  }

  .detailsViewFooter {
    flex-direction: column;
  }

  .detailsViewFooter > button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media all and (max-width: 650px) {
  .centerPopupModal {
    width: 100% !important;
  }
}

.reach-skip-link {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
}

.reach-skip-link:focus {
  z-index: 9000;
  background: rgb(254, 252, 191);
  border: 2px solid rgb(24, 100, 171);
  color: rgb(24, 100, 171);
  outline: none;
  padding: 1rem;
  position: fixed;
  top: 10px;
  left: 10px;
  width: auto;
  height: auto;
  clip: auto;
}

.consentNumber {
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  color: #173e5c;
}
