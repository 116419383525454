.notFoundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-flow: column nowrap;
}

.notFoundContainer h1 {
  font-size: 36px;
  font-weight: 600;
}

.notFoundContainer p {
  text-align: center;
  margin-bottom: 40px;
}
